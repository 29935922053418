import moment from "moment";

export const convertDate = (date) => {
	return moment(date * 1000).format("lll");
};

export function generateParams(data) {
	var params = "";
	for (let key in data) {
		if (Object.prototype.hasOwnProperty.call(data, key) && (data[key] || data[key] === 0)) {
			if (typeof data[key] === "object") {
				params += '"' + key + '"' + ":" + JSON.stringify(data[key]);
			} else {
				params = '"' + key + '"' + ":" + data[key];
			}

			params += ", ";
		}
	}
	return "?rbql={" + params.slice(0, -2) + "}";
}

export function getQueryParamsData(args) {
	let queryParmas = {};
	// pagination configuration
	if (args.pagination) {
		queryParmas["pagination"] = args.pagination;
	}
	// filter configuration
	if (args.filters) {
		queryParmas["filters"] = args.filters;
	}
	if (args.sort) {
		queryParmas["sort"] = args.sort;
	}
	if (args.fields) {
		queryParmas["fields"] = args.fields;
	}
	if (args.joins) {
		queryParmas["joins"] = args.joins;
	}
	if (args.whereRawQuery) {
		queryParmas["whereRawQuery"] = args.whereRawQuery;
	}
	if (args.other_options && args.other_options != "") {
		queryParmas["other_options"] = args.other_options;
	}
	// ready api url
	return queryParmas;
}
