import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import queryParamsConfig from "../../configs/queryParamsConfig";
import { generateParams, getQueryParamsData } from "../../core/utils.js";
import dataConfigs from "../../configs/dataConfigs";
import _ from "lodash";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Tag, Body, Small, AppThemes } from "@roambee/client-styleguide";
import "../../App.scss";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
import { Card, CardContent, CardContentContainer, CardContentList, CardContentTitle, Heading, IconContainer, InlineButton, TagContainer, Link, NoData } from "./styles";
import { Skeleton } from "@mui/material";
import { MasterDataContext } from "../../App";

const RightSideIntegration = () => {
	const [integration, setIntegration] = useState([]);
	const [active, setActive] = useState(0);
	const [inactive, setInactive] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const masterData = useContext(MasterDataContext);

	const { id } = useParams();
	const count = 4;
	const navigate = useNavigate();
	const handleClick = () => navigate("/integrationhub/configuration");
	const filterClick = (id) => {
		navigate("/integrationhub/dashboard/" + id);
		fetchIntegrations();
	};

	useEffect(() => {
		fetchIntegrations();
	}, [id]);

	const fetchIntegrations = () => {
		let args: any = queryParamsConfig.getIntegrationList;

		if (parseInt(id) > 0)
			args = {
				...args,
				filters: [{ name: "id", op: "eq", value: id }]
			};

		const opts = {
			type: args.module,
			data: { rbql: getQueryParamsData(args) }
		};

		const url = dataConfigs.CRUDAPI.value + args.module + "/" + generateParams(opts.data.rbql);

		API("GET", url, {}).then((content) => {
			let integrationsList = [];

			if (content.total == 0) {
				setIsLoading(false);
				// return;
			}

			try {
				content.data.forEach((item) => {
					item.image = item.connection__type ? _.find(masterData.connector, { key: item.connection__type }).logo_path : null;
					item.mode = _.find(dataConfigs.ModeValues, { key: item.integration_connection__mode })?.logo;
					integrationsList.push(item);
				});

				integrationsList = _.chain(integrationsList)
					.groupBy("id")
					.map((value, key) => ({ id: key, integrations: value }))
					.value();
				setIntegration(integrationsList.slice(0, count) || []);

				const active = _.chain(content.data)
					.filter((item) => item.active == 1)
					.groupBy((item) => `${item.id} `)
					.value();
				const inActive = _.chain(content.data)
					.filter((item) => item.active == 0)
					.groupBy((item) => `${item.id} `)
					.value();

				const countActive = Object.keys(active).length;
				const countInactive = Object.keys(inActive).length;

				setActive(countActive);
				setInactive(countInactive);
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log("err");
			}
		});
	};
	return (
		<Card>
			<Heading>
				<Body $bold>
					Integration
					{isLoading ? (
						<>
							{integration.length > 0 ? (
								<Tag label={active} color="#19311E" backgroundColor="#E3F5E1" small={true} />
							) : (
								<Skeleton
									sx={{
										height: "25px",
										width: "25px",
										transform: "none",
										bgcolor: "${AppThemes.light.colors.neutral200}"
									}}
									animation="wave"
								/>
							)}
						</>
					) : (
						<Tag label={active} color="#19311E" backgroundColor="#E8E7ED" small={true} />
					)}
					{isLoading ? (
						<>
							{integration.length > 0 ? (
								<Tag label={inactive} color="#7A1000" backgroundColor="#FFE9E6" small={true} />
							) : (
								<Skeleton
									sx={{
										height: "25px",
										width: "25px",
										transform: "none",
										bgcolor: "${AppThemes.light.colors.neutral200}"
									}}
									animation="wave"
								/>
							)}
						</>
					) : (
						<Tag label={0} color="#7A1000" backgroundColor="#E8E7ED" small={true} />
					)}
				</Body>
				<InlineButton label="View all" onClick={() => handleClick()} />
			</Heading>
			{isLoading ? (
				<>
					{integration.length > 0 ? (
						<CardContent>
							<CardContentList>
								{integration.map((data, index) => {
									return (
										<CardContentContainer key={index}>
											<CardContentTitle>
												<Small style={Link} $bold onClick={() => filterClick(data?.id)}>
													{data?.integrations[0].name}
												</Small>
												<TagContainer label={data?.integrations[0].accounts__name} color="#100F16" backgroundColor="#F7F6F9" small={true} />
											</CardContentTitle>
											<IconContainer aria-hidden>
												{data?.integrations.map((item, i) => {
													return (
														item.image && (
															<TagContainer
																key={i}
																$width={60}
																color="#100F16"
																backgroundColor="#FFFFFF"
																label={
																	<>
																		<img src={require(`../../assets/images/${item?.image}`)} height={25} alt="" />
																		<span> {item.mode} </span>
																	</>
																}
															/>
														)
													);
												})}
											</IconContainer>
										</CardContentContainer>
									);
								})}
							</CardContentList>
						</CardContent>
					) : (
						<Skeleton
							sx={{
								height: "290px",
								width: "100%",
								mb: 2,
								transform: "none",
								bgcolor: "${AppThemes.light.colors.neutral200}"
							}}
							animation="wave"
						/>
					)}
				</>
			) : (
				<NoData>No Data!</NoData>
			)}
		</Card>
		// <Card>
		// 	<Box boxShadow={3}>
		// 		<CardHeader
		// 			titleTypographyProps={{
		// 				variant: "subtitle1"
		// 			}}
		// 			title={
		// 				<div>
		// 					<Grid container>
		// 						<Grid item style={{ fontSize: "13px" }} xs={5}>
		// 							Integration
		// 						</Grid>
		// 						<Grid item xs={3}>
		// 							<Tag label={active} color="#3e9b41" backgroundColor="#d3ecd7" small={true} />
		// 						</Grid>
		// 						<Grid item xs={3}>
		// 							<Tag label={inactive} color="#F15C62" backgroundColor="#FFDDDE" small={true} />
		// 						</Grid>
		// 					</Grid>
		// 				</div>
		// 			}
		// 			action={
		// 				<Button
		// 					style={{ fontSize: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
		// 					onClick={() => handleClick()}
		// 				>
		// 					View more
		// 				</Button>
		// 			}
		// 		/>
		// 	</Box>
		// 	<List>
		// 		{integration.slice(0, count).map((data, index) => {
		// 			return (
		// 				<ListItem key={index} sx={{ paddingY: "0" }}>
		// 					<ListItemText
		// 						primaryTypographyProps={{ fontSize: "12px" }}
		// 						primary={data?.integrations[0].name}
		// 						secondary={
		// 							<AccountTag
		// 								label={
		// 									<Box component={"span"} sx={{ fontSize: "10px", marginTop: "2px" }} role="presentation">
		// 										{data?.integrations[0].accounts__name}
		// 									</Box>
		// 								}
		// 							/>
		// 						}
		// 					/>
		// 					{data?.integrations.map((item, i) => {
		// 						return (
		// 							item.image && (
		// 								<AccountTag
		// 									key={i}
		// 									label={
		// 										<Box
		// 											component={"span"}
		// 											display="flex"
		// 											justifyContent="center"
		// 											alignItems="center"
		// 											role="presentation"
		// 										>
		// 											<img src={require(`/ src / assets / images / ${ item.image } `)} height={15} alt="" />
		// 											&nbsp;{item.mode}
		// 										</Box>
		// 									}
		// 								/>
		// 							)
		// 						);
		// 					})}
		// 				</ListItem>
		// 			);
		// 		})}
		// 	</List>
		// </Card>
	);
};

export default RightSideIntegration;
