// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AppThemes, Tag, Body, Button } from "@roambee/client-styleguide";
import styled, { css } from "styled-components";

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	padding-block-start: 1rem;
	padding-inline: 1rem;
	overflow: hidden;
	background-color: ${AppThemes.light.colors.white};
	border-radius: 8px;
	box-shadow: ${AppThemes.light.shadows.shadow12};
`;
export const Heading = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-block-end: 1rem;
	${Body} {
		display: inline-flex;
		justify-content: space-between;
		gap: 0.2rem;
	}
`;

export const InlineButton = styled(Button).attrs({ variant: "no-bg" })`
	padding-inline: 0;
	padding: 0;
	height: inherit;
	vertical-align: middle;
`;

export const CardContent = styled.div``;

export const CardContentList = styled.ul``;

export const CardContentContainer = styled.li`
	position: relative;
	padding-inline: 1rem;
	padding-block: 0.5rem 0.75rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.25rem;
	margin-inline: -1rem;
	border-block-start: 1px solid ${AppThemes.light.colors.neutral200};

	&:focus-within,
	&:hover {
		background-color: ${AppThemes.light.colors.neutral100};
	}
`;

export const NoData = styled.div`
	position: relative;
	padding-inline: 1rem;
	padding-block: 1.25rem 1.25rem;
	text-align: center;
	margin-inline: -1rem;
	border-block-start: 1px solid ${AppThemes.light.colors.neutral200};
`;

export const CardContentTitle = styled.div``;

export const IconContainer = styled.div`
	display: flex;
`;

export const TagContainer = styled(Tag)`
	border: 1px solid #cfced7;
	margin: 0.2rem;
	${({ $width }) =>
		$width &&
		css`
			width: ${$width}px;
		`}
	${({ $height }) =>
		$height &&
		css`
			height: ${$height}px;
		`}
	& > p {
		display: flex;
		justify-content: center;
		align-items: center;
		& > span {
			margin-left: 5px;
		}
	}
`;

export const Link = {
	color: "#6245D9",
	cursor: "pointer"
};

export const InputOutputDataContainer = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
	font-size: 0.875rem;
	text-align: center;
`;

export const Filename = styled.div`
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 90%;
	overflow: hidden;
	font-size: 0.875rem;
`;

export const ContainerTotalLabel = styled.div`
	text-align: center;
	padding: 5px;
`;

export const ContainerShipmentLabel = styled.div`
	text-align: center;
	padding: 5px;
	padding-top: 22px;
`;

export const TransactionDetailContainer = styled.div`
	height: 100%;
	width: 100%;
	margin-top: 8px;
`;
