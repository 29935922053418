const queryParamsConfig = {
	getConnectionList: {
		module: "connection",
		joins: [
			{
				join_type: "left_join",
				table_name: "integration_connection",
				left_table_attribute: "id",
				right_table_attribute: "connection_id",
				fields: [
					{
						name: "integration_id"
					},
					{
						name: "mode"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "integration",
				left_table_attribute: "integration_connection.integration_id",
				right_table_attribute: "id",
				fields: [
					{
						name: "account_id"
					},
					{
						name: "name"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "accounts",
				left_table_attribute: "account_id",
				right_table_attribute: "id",
				fields: [
					{
						name: "name"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "users",
				left_table_attribute: "created_by",
				right_table_attribute: "id",
				fields: [
					{
						name: "first_name"
					},
					{
						name: "last_name"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "connector",
				left_table_attribute: "type",
				right_table_attribute: "type",
				fields: [
					{
						name: "config"
					}
				]
			}
		]
	},

	getIntegrationList: {
		module: "integration",
		other_options: {
			return_all: true
		},
		joins: [
			{
				join_type: "left_join",
				table_name: "accounts",
				left_table_attribute: "account_id",
				right_table_attribute: "id",
				fields: [
					{
						name: "name"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "integration_connection",
				left_table_attribute: "id",
				right_table_attribute: "integration_id",
				fields: [{ name: "connection_id" }, { name: "mode" }]
			},
			{
				join_type: "left_join",
				table_name: "connection",
				left_table_attribute: "integration_connection.connection_id",
				right_table_attribute: "id",
				fields: [{ name: "name" }, { name: "type" }]
			},
			{
				join_type: "left_join",
				table_name: "users",
				left_table_attribute: "created_by",
				right_table_attribute: "id",
				fields: [{ name: "first_name" }, { name: "last_name" }]
			},
			{
				join_type: "left_join",
				table_name: "users",
				left_table_attribute: "updated_by",
				right_table_attribute: "id",
				table_alias: "users_updated_by",
				fields: [{ name: "first_name" }, { name: "last_name" }]
			}
		]
	},
	getIntegrationTypeList: {
		module: "integration_type",
		other_options: {
			return_all: true
		}
	},

	getConnectors: {
		module: "connector",
		other_options: {
			return_all: true
		},
		sort: [{ order: "asc", name: "id" }]
	},

	getTransactionsList: {
		module: "integration_transaction",
		sort: [{ order: "desc", name: "created_date" }],
		joins: [
			{
				join_type: "left_join",
				table_name: "integration",
				left_table_attribute: "integration_id",
				right_table_attribute: "id",
				fields: [
					{
						name: "name"
					},
					{
						name: "type"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "accounts",
				left_table_attribute: "account_id",
				right_table_attribute: "id",
				fields: [
					{
						name: "name"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "users",
				left_table_attribute: "resolved_by",
				right_table_attribute: "id",
				fields: [
					{
						name: "first_name"
					},
					{
						name: "last_name"
					}
				]
			},
			{
				join_type: "left_join",
				table_name: "users",
				left_table_attribute: "updated_by",
				right_table_attribute: "id",
				table_alias: "users_updated_by",
				fields: [
					{
						name: "first_name"
					},
					{
						name: "last_name"
					}
				]
			}
		]
	}
};

export default queryParamsConfig;
