// import React from "react";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import dataConfigs from "../../configs/dataConfigs";
import queryParamsConfig from "../../configs/queryParamsConfig";
import { Grid, Box, IconButton } from "@mui/material";
import styled from "styled-components";
import "../../App.scss";
import { convertDate, getQueryParamsData, generateParams } from "../../core/utils.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Tag, Table, InputSearch, AppThemes } from "@roambee/client-styleguide";
import { useNavigate, useParams } from "react-router-dom";
import { InputOutputDataContainer } from "../../pages/rightsidePanel/styles";
import { MasterDataContext } from "../../App";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastContainer, toast } from "react-toastify";

const CounterTag = styled(Tag)`
	display: inline-flex;
	margin: 0 2px;
	cursor: pointer;
`;
export const Search = styled(InputSearch)`
	width: 100%;
`;
const showToastMessage = () => {
	toast.success("Successfully copied!", {
		position: "top-right",
		autoClose: 1000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
		theme: "light"
	});
};

const TransactionList = () => {
	const [transactions, setTransactions] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tableEvent, setTableEvent] = useState<any>({
		page: 1,
		pageSize: 10,
		search: [],
		sort: {}
	});
	const [total, setTotal] = useState<number>();
	const [isLoading, setIsLoading] = useState(true);
	const [inputHover, setInputHover] = useState(0);
	const { id } = useParams();
	const masterData = useContext(MasterDataContext);

	const navigate = useNavigate();

	const columns = [
		{
			field: "status",
			headerName: "Status",
			width: 9,
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => {
				return <Tag label={params?.row.statusName} color={params?.row?.tagColor} backgroundColor={params?.row?.backgroundTagColor} />;
			},

			searchType: {
				type: "selectbox",
				options: dataConfigs.TransactionStatus,
				operator: "eq"
			}
		},
		{
			field: "record_count",
			headerName: "Shipments Stats",
			width: 12,
			isSortable: false,
			isSearchable: false,
			renderCell: (params) => (
				<Box sx={{ alignItems: "center" }}>
					<CounterTag
						label={
							<Box title="Total Shipments" component={"span"} role="presentation">
								{params?.value}
							</Box>
						}
						small={true}
						color={AppThemes.light.colors.oceanicBlue800}
						backgroundColor={AppThemes.light.colors.oceanicBlue50}
					/>
					<CounterTag
						label={
							<Box title="Success Shipments" component={"span"} role="presentation">
								{params?.row.success_count}
							</Box>
						}
						small={true}
						color={AppThemes.light.colors.supportSuccess800}
						backgroundColor={AppThemes.light.colors.supportSuccess50}
					/>
					<CounterTag
						label={
							<Box title="Failed Shipments" component={"span"} role="presentation">
								{params?.row.failure_count}
							</Box>
						}
						small={true}
						color={AppThemes.light.colors.supportError800}
						backgroundColor={AppThemes.light.colors.supportError50}
					/>
				</Box>
			)
		},
		{
			field: "input_filename",
			headerName: "File Name",
			width: 12,
			isSortable: true,
			hasAction: true,
			isSearchable: true,
			onActionClick: (action) => {
				handleId(action.id);
			}
		},
		{ field: "accounts__name", headerName: "Account", width: 9, isSortable: true, isSearchable: true },
		{ field: "integration__name", headerName: "Integration", width: 10, isSortable: true, isSearchable: true },
		{
			field: "integration__type",
			headerName: "Type",
			width: 11,
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => (
				<span className="table-cell-span" role="presentation">
					{params?.row.typeName}
				</span>
			),
			searchType: { type: "selectbox", options: masterData.integrationType, operator: "eq" }
		},
		{
			field: "failure_reason",
			headerName: "Details",
			width: 10,
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => (
				// <Filename>
				// 	<span
				// 		// style={linkStyle}
				// 		title={params?.value}
				// 		role="presentation"
				// 	>
				// 		{params?.value}
				// 	</span>
				// </Filename>
				<div onMouseEnter={() => setInputHover(params.row.id)} onMouseLeave={() => setInputHover(0)}>
					<Grid container className="alignCenter">
						<Grid item xs={11}>
							<InputOutputDataContainer>
								<span title={params.row.failure_reason}>{params.row.failure_reason}</span>
							</InputOutputDataContainer>
						</Grid>
						<Grid item xs={1}>
							{inputHover == params.row.id && params.row.failure_reason ? (
								<IconButton
									onClick={() => {
										showToastMessage();
										navigator.clipboard.writeText(params.row.failure_reason);
									}}
								>
									<ContentCopyIcon fontSize="small" />
								</IconButton>
							) : (
								""
							)}
						</Grid>
					</Grid>
				</div>
			)
		},
		{
			field: "created_date",
			headerName: "Received",
			width: 14,
			isSortable: true,
			isSearchable: false,
			isDateFilter: true,
			renderCell: (params) => (
				<span className="table-cell-span" role="presentation">
					{params?.row.receivedDate}
				</span>
			)
		},
		{
			field: "resolved_date",
			headerName: "Resolved Date",
			width: 14,
			isSortable: true,
			isSearchable: false,
			isDateFilter: true,
			renderCell: (params) => (
				<span className="table-cell-span" role="presentation">
					{params?.row.resolvedDate}
				</span>
			)
		},
		{ field: "resolvedBy", headerName: "Resolved By", width: 9, isSortable: true, isSearchable: true },
		{
			field: "updated_date",
			headerName: "Updated Date",
			width: 14,
			isSortable: true,
			isSearchable: false,
			isDateFilter: true,
			renderCell: (params) => (
				<span className="table-cell-span" role="presentation">
					{params?.row.updatedDate}
				</span>
			)
		},
		{
			field: "updatedBy",
			headerName: "Updated By",
			width: 9,
			isSortable: true,
			isSearchable: true
		}
	];

	useEffect(() => {
		getData(tableEvent);
	}, [id]);

	function getData(event = null) {
		if (!event) event = tableEvent;
		/* eslint-disable @typescript-eslint/no-explicit-any */
		let args: any = queryParamsConfig.getTransactionsList;
		args = {
			...args,
			pagination: { page_size: event.pageSize, page_num: event.page || 1 },
			filters: event?.search.length > 0 ? event?.search.filter((c) => c.field !== "resolvedBy" && c.field !== "updatedBy").map((search) => ({ name: search.field, op: search.operator, value: search.value })) : [],
			sort: [
				{
					order: (event?.sort?.status && event?.sort?.status != "default" && (event?.sort?.status == "descendent" ? "desc" : "asc")) || "desc",
					name: event?.sort?.field && event?.sort?.status != "default" ? event?.sort?.field : "created_date"
				}
			]
		};

		if (parseInt(id) > 0) {
			args.filters = args.filters.concat({ name: "integration_id", op: "eq", value: id });
		}

		const sortQuery = "";
		if (event?.sort?.field == "updatedBy") {
			args["sort"] = [
				{ order: args["sort"][0].order, name: "users_updated_by__first_name" },
				{ order: args["sort"][0].order, name: "users_updated_by__last_name" }
			];
		}

		if (event?.sort?.field == "resolvedBy") {
			args["sort"] = [
				{ order: args["sort"][0].order, name: "users__first_name" },
				{ order: args["sort"][0].order, name: "users__last_name" }
			];
		}

		const updatedBy = event.search.find((c) => c.field == "updatedBy");
		let filterQuery = "";
		if (updatedBy) filterQuery = " concat(users_updated_by.first_name," + "' '" + ",users_updated_by.last_name) like '" + "%25" + updatedBy.value + "%25";

		const resolvedBy = event.search.find((c) => c.field == "resolvedBy");
		// let filterQuery = '';
		if (resolvedBy) filterQuery = (filterQuery ? ", " : "") + " concat(users.first_name," + "' '" + ",users.last_name) like '" + "%25" + resolvedBy.value + "%25";

		let finalWhereQuery = "";
		if (sortQuery || filterQuery) finalWhereQuery = ',"whereRawQuery":"' + filterQuery + sortQuery + "'\"}";

		const opts = {
			type: args.module,
			data: { rbql: getQueryParamsData(args) }
		};
		const url = dataConfigs.CRUDAPI.value + args.module + "/" + (finalWhereQuery ? generateParams(opts.data.rbql).slice(0, -1) : generateParams(opts.data.rbql)) + finalWhereQuery;

		API("GET", url, opts).then((content) => {
			if (content.total == 0) {
				setIsLoading(false);
				// return;
			}

			content.data = content.data || [];

			content?.data?.forEach((item) => {
				item.statusName = _.find(dataConfigs.TransactionStatus, { key: item.status }).value;
				switch (item.statusName) {
					case "Success":
						item["tagColor"] = AppThemes.light.colors.supportSuccess800;
						item["backgroundTagColor"] = AppThemes.light.colors.supportSuccess50;
						break;
					case "Failed":
						item["tagColor"] = AppThemes.light.colors.supportError800;
						item["backgroundTagColor"] = AppThemes.light.colors.supportError50;
						break;
					case "Partial Success":
						item["tagColor"] = AppThemes.light.colors.yellowBee800;
						item["backgroundTagColor"] = AppThemes.light.colors.yellowBee50;
						break;
					case "Resolved":
						item["tagColor"] = AppThemes.light.colors.purpleSunset800;
						item["backgroundTagColor"] = AppThemes.light.colors.purpleSunset50;
						break;
				}
				item.input_filename = item.input_filename ? item.input_filename : "View Details";
				item.typeName = _.find(masterData.integrationType, { key: item.integration__type })?.value;
				item.receivedDate = convertDate(item.created_date);
				item.resolvedDate = item.resolved_date ? convertDate(item.resolved_date) : null;
				item.updatedDate = item.updated_date ? convertDate(item.updated_date) : null;
				item.resolvedBy = item.users__first_name && item.users__last_name ? item.users__first_name + " " + item?.users__last_name : " ";
				item.updatedBy = item.users_updated_by__first_name && item.users_updated_by__last_name ? item.users_updated_by__first_name + " " + item?.users_updated_by__last_name : " ";
			});

			setTableData(content.data);
			setTotal(content.total);

			setTransactions(content.data);
			setTableData(content.data);
		});
	}
	const handleId = (data) => {
		navigate("/integrationhub/transactiondetail/" + data);
	};

	const handleTableEvent = (event) => {
		setTableEvent(event);
		getData(event);
	};

	return (
		<Grid container item xs={12}>
			<Table showFilters={false} isSelectable={false} columns={columns} data={transactions} total={total || tableData.length} page={tableEvent.page || 1} pageSize={tableEvent.pageSize || 10} handleEvent={(event) => handleTableEvent(event)} isLoading={isLoading} />
			<ToastContainer />
		</Grid>
	);
};

export default TransactionList;
