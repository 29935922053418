import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dataConfigs from "../../configs/dataConfigs";
import queryParamsConfig from "../../configs/queryParamsConfig";
import _ from "lodash";
import { Add } from "@carbon/icons-react";

import ConnectionDetailsModal from "../../components/connections/connectionDetailsModal";

import { generateParams, getQueryParamsData } from "../../core/utils.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
import { Card, CardContent, CardContentContainer, Heading, InlineButton, TagContainer, NoData } from "./styles";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AppThemes, Body } from "@roambee/client-styleguide";
import { Skeleton } from "@mui/material";
import { MasterDataContext } from "../../App";
// import { API } from "../../core/API";

const ContentContainer = styled(CardContentContainer)`
	display: inline-flex;
	flex-wrap: wrap;
	padding-block: 0.5rem 0;
	padding-block-start: 1rem;
	&:focus-within,
	&:hover {
		background-color: ${AppThemes.light.colors.white};
	}
`;

const ConnectorTag = styled(TagContainer)`
	margin-block-end: 1rem;
	cursor: pointer;
	&:focus-within,
	&:hover {
		background-color: ${AppThemes.light.colors.neutral100};
	}
`;

const RightSideConnectors = () => {
	const navigate = useNavigate();
	const handleClick = () => navigate("/integrationhub/connections");

	const [connectorList, setConnectorList] = useState([]);
	const [connector, setConnector] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const masterData = useContext(MasterDataContext);

	const handleClickOpen = (_connector) => {
		setOpen(true);
		setConnector(_connector);
	};

	const handleClose = () => {
		setOpen(false);
	};
	useEffect(() => {
		const args = queryParamsConfig.getConnectors;

		const opts = {
			type: args.module,
			data: { rbql: getQueryParamsData(args) }
		};

		const url = dataConfigs.CRUDAPI.value + args.module + "/" + generateParams(opts.data.rbql);

		API("GET", url, {}).then((content) => {
			//console.log("data ", content.data);

			if (content.total == 0) {
				setIsLoading(false);
				// return;
			}

			content.data.forEach((item) => {
				item.image = _.find(masterData.connector, { key: item.type })?.logo_path;
				item.isConnector = true;
			});
			setConnectorList(content.data);
		});
	}, []);

	return (
		<Card>
			<Heading>
				<Body $bold>Connectors</Body>
				<InlineButton label="View all" onClick={() => handleClick()} />
			</Heading>
			{isLoading ? (
				<>
					{connectorList.length > 0 ? (
						<CardContent>
							<ContentContainer>
								{connectorList.map((data, index) => {
									return <ConnectorTag onClick={() => handleClickOpen(data)} key={index} $width={74} $height={50} color="#100F16" backgroundColor="#FFFFFF" label={data && data.image && <img src={require(`../../assets/images/${data?.image}`)} alt="demo" height={30} loading="lazy" />} />;
								})}
								<ConnectorTag $width={74} $height={50} color="#100F16" backgroundColor="#FFFFFF" label={<Add size={20} />} />
							</ContentContainer>
						</CardContent>
					) : (
						<Skeleton
							sx={{
								height: "80px",
								width: "100%",
								mb: 2,
								bgcolor: "${AppThemes.light.colors.neutral200}",
								transform: "none"
							}}
							animation="wave"
						/>
					)}
				</>
			) : (
				<NoData>No Data!</NoData>
			)}
			<ConnectionDetailsModal connection={connector} open={open} onClose={() => handleClose()} />
		</Card>
		// <Card>
		// 	<Box boxShadow={3}>
		// 		<CardHeader
		// 			titleTypographyProps={{
		// 				variant: "subtitle1"
		// 			}}
		// 			title="Connectors"
		// 			action={
		// 				<Button
		// 					style={{ fontSize: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}
		// 					onClick={() => handleClick()}
		// 				>
		// 					View more
		// 				</Button>
		// 			}
		// 		/>
		// 	</Box>
		// 	{/* <hr style={{ color: "black", marginTop: "-10px" }} /> */}

		// 	<CardContent>
		// 		<Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
		// 			{connectorList.map((data, index) => {
		// 				return (
		// 					<Grid
		// 						sx={{ cursor: "pointer" }}
		// 						onClick={() => handleClickOpen(data)}
		// 						key={index}
		// 						item
		// 						xs={2}
		// 						sm={4}
		// 						md={4}
		// 					>
		// 						<Item>
		// 							{data && (
		// 								<img src={require(`/src/assets/images/${data.image}`)} alt="demo" height={25} loading="lazy" />
		// 							)}
		// 						</Item>
		// 					</Grid>
		// 				);
		// 			})}
		// 			<Grid item xs={2} sm={4} md={4}>
		// 				<Item sx={{ fontSize: "25px" }}>+</Item>
		// 			</Grid>
		// 		</Grid>
		// 		<ConnectionDetailsModal connection={connector} open={open} onClose={() => handleClose()} />
		// 	</CardContent>
		// </Card>
	);
};

export default RightSideConnectors;
