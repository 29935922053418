// import React from "react";
import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import dataConfigs from "../../configs/dataConfigs";
import queryParamsConfig from "../../configs/queryParamsConfig";
import { Box, Grid } from "@mui/material";
import RightSideIntegration from "../../pages/rightsidePanel/RightSideIntegration";
import ConnectionDetailsModal from "./connectionDetailsModal";
import Connectors from "./connectors";
import { convertDate, getQueryParamsData, generateParams } from "../../core/utils.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Table, InputSearch } from "@roambee/client-styleguide";
import styled from "styled-components";
import { MasterDataContext } from "../../App";

const linkStyle = {
	color: "black",
	cursor: "pointer",
	fontSize: "0.875rem"
};

const TypeStyle = {
	display: "flex",
	alignItems: "center"
};

const tableDiv = {
	height: "100%",
	width: "100%",
	marginTop: "-16px"
};

const rightSideDiv = {
	marginTop: "-16px"
};

// const Tags = styled(Box)`
// 	display: inline-flex;
// 	margin: 0 2px;
// `;

export const Search = styled(InputSearch)`
	width: 100%;
`;

const ConnectionsList = () => {
	const [open, setOpen] = React.useState(false);
	const [connection, setConnection] = React.useState(null);
	const [connections, setConnections] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tableEvent, setTableEvent] = useState<any>({
		page: 1,
		pageSize: 10,
		search: [],
		sort: {}
	});
	const [total, setTotal] = useState<number>();
	const masterData = useContext(MasterDataContext);
	const [isLoading, setIsLoading] = useState(true);

	const handleClickOpen = (_connection) => {
		setOpen(true);
		setConnection(_.find(connections, { id: _connection }));
	};

	const handleClose = (isGetData) => {
		setOpen(false);
		if (isGetData) getData(tableEvent);
	};

	const columns = [
		{
			field: "name",
			headerName: "Name",
			width: 12,
			isSortable: true,
			isSearchable: true,
			hasAction: true,
			onActionClick: (params) => {
				handleClickOpen(params.id);
			}
		},
		{
			field: "type",
			headerName: "Type",
			width: 8,
			isSortable: true,
			isSearchable: true,
			hasAction: true,
			renderCell: (params) => (
				<div role="presentation" style={TypeStyle} onClick={() => handleClickOpen(params.row.id)}>
					{/* <Box component="img" height={20} src={params.row.typeLogo} /> */}
					<Box component="img" height={20} src={require(`../../assets/images/${params.row.typeLogo}`)} />
					&nbsp;
					<span style={linkStyle} role="presentation">
						{params.row.typeName}
					</span>
				</div>
			),
			searchType: { type: "selectbox", options: masterData.connector, operator: "eq" }
		},
		{ field: "accounts__name", headerName: "Account", width: 9, isSortable: true, isSearchable: true },
		{
			field: "active",
			headerName: "Status",
			width: 7,
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => (
				<span className="table-cell-span" role="presentation">
					{params.row.activeStatus}
				</span>
			),
			searchType: { type: "selectbox", options: dataConfigs.ConnectionStatus, operator: "eq" }
		},
		{ field: "integration__name", headerName: "Integration", width: 9, isSortable: true, isSearchable: true },
		{
			field: "created_date",
			headerName: "Date Created",
			width: 14,
			isSortable: true,
			isSearchable: false,
			isDateFilter: true,
			renderCell: (params) => (
				<span className="table-cell-span" role="presentation">
					{params.row.createdDate}
				</span>
			)
		},
		{
			field: "updated_date",
			headerName: "Date Modified",
			width: 14,
			isSortable: true,
			isSearchable: false,
			isDateFilter: true,
			renderCell: (params) => (
				<span className="table-cell-span" role="presentation">
					{params.row.updatedDate}
				</span>
			)
		},
		{ field: "createdBy", headerName: "Created By", width: 9, isSortable: true, isSearchable: true }
	];

	useEffect(() => {
		getData(tableEvent);
	}, []);

	function getData(event = null) {
		if (!event) event = tableEvent;
		/* eslint-disable @typescript-eslint/no-explicit-any */
		let args: any = queryParamsConfig.getConnectionList;
		args = {
			...args,
			pagination: { page_size: event.pageSize, page_num: event.page || 1 },
			filters: event?.search.length > 0 ? event?.search.filter((c) => c.field !== "createdBy").map((search) => ({ name: search.field, op: search.operator, value: search.value })) : [],
			sort: [
				{
					order: (event?.sort?.status && event?.sort?.status != "default" && (event?.sort?.status == "descendent" ? "desc" : "asc")) || "desc",
					name: event?.sort?.field && event?.sort?.status != "default" ? event?.sort?.field : "created_date"
				}
			]
		};

		const sortQuery = "";
		if (event?.sort?.field == "createdBy") {
			args["sort"] = [
				{ order: args["sort"][0].order, name: "users__first_name" },
				{ order: args["sort"][0].order, name: "users__last_name" }
			];
		}

		const createdBy = event.search.find((c) => c.field == "createdBy");
		let filterQuery = "";
		if (createdBy) filterQuery = " concat(users.first_name," + "' '" + ",users.last_name) like '" + "%25" + createdBy.value + "%25";

		let finalWhereQuery = "";
		if (sortQuery || filterQuery) finalWhereQuery = ',"whereRawQuery":"' + filterQuery + sortQuery + "'\"}";

		const opts = {
			type: args.module,
			data: { rbql: getQueryParamsData(args) }
		};

		const url = dataConfigs.CRUDAPI.value + args.module + "/" + (finalWhereQuery ? generateParams(opts.data.rbql).slice(0, -1) : generateParams(opts.data.rbql)) + finalWhereQuery;

		API("GET", url, {}).then((content) => {
			if (content.total == 0) {
				setIsLoading(false);
				// return;
			}
			content.data.forEach((item) => {
				item.typeName = _.find(masterData.connector, { key: item.type }).value;
				item.typeLogo = _.find(masterData.connector, { key: item.type }).logo_path;

				item.activeStatus = item.active == 1 ? "Active" : "InActive";
				item.createdDate = convertDate(item.created_date);
				item.updatedDate = item.updated_date ? convertDate(item.updated_date) : null;
				item.createdBy = item.users__first_name + " " + item.users__last_name;
			});
			setTableData(content.data);
			setTotal(content.total);

			const connectionsData = [...content.data];
			setConnections(connectionsData);
		});
	}

	const handleTableEvent = (event) => {
		setTableEvent(event);
		getData(event);
	};

	return (
		<div>
			<Grid container spacing={2}>
				<Connectors getData={getData} connections={connections} />
				<Grid item xs={9}>
					<div style={tableDiv}>
						<Table showFilters={false} isSelectable={false} columns={columns} data={connections} total={total || tableData.length} page={tableEvent.page || 1} pageSize={tableEvent.pageSize || 10} handleEvent={(event) => handleTableEvent(event)} isLoading={isLoading} />
					</div>
				</Grid>
				<Grid item xs={3}>
					<div style={rightSideDiv}>
						<RightSideIntegration />
					</div>
				</Grid>
			</Grid>

			<ConnectionDetailsModal getData={(e) => getData(null)} connection={connection} connections={connections} open={open} onClose={(e) => handleClose(e)} />
		</div>
	);
};

export default ConnectionsList;
