import { Outlet, useLocation, useParams } from "react-router-dom";

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { EventEmitter, getAuthUser } from "@roambee/client-utility";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { Breadcrumb } from "@roambee/client-styleguide";
import Topbar from "./Topbar";
import styled from "styled-components";
import { useState } from "react";

const CustomBreadcrumb = styled(Breadcrumb)`
	padding-top: 25px;
`;

const MainLayout = () => {
	const location = useLocation();
	const [isWide, setWide] = useState(true);
	const user = getAuthUser() || {};
	const { id } = useParams();

	EventEmitter.addListener("toggleSideNav", (eventData) => {
		setWide(eventData);
	});

	return (
		<section id="main-layout" className={isWide ? "sidenav-normal" : "sidenav-small"}>
			<section id="header">
				<Topbar user={user} />
			</section>
			<section id="container-layout">
				{location.pathname.includes("transactiondetail") && (
					<CustomBreadcrumb
						className={{
							table: {
								disable: true
							}
						}}
						links={[
							{
								name: "Integration Hub",
								value: "/integrationhub/dashboard"
							},
							{
								name: "Transaction Detail",
								value: "/integrationhub/dashboard/" + id
							}
						]}
					/>
				)}

				<Outlet />
			</section>
		</section>
	);
};

export default MainLayout;
