import * as React from "react";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import dataConfigs from "../configs/dataConfigs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button, InputText, Modal, InfoDetail, Title, Select } from "@roambee/client-styleguide";
import styled from "styled-components";
import { MasterDataContext } from "../App";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const linkStyle = {
	textAlign: "justify" as const,
	padding: "0"
	// marginLeft: "-16px"
};

const ModalTitle = styled(InfoDetail)`
	margin-top: 16px;
`;
//todo remove after download api in API.tsx
const BASE_URL = process.env.CLIENT_HC10_API_URL;
export default function IntegrationConfigModel(props) {
	const [name, setName] = React.useState("");
	const [dataFlow, setDataFlow] = React.useState("");
	const [type, setType] = React.useState("");
	const [connection, setConnection] = React.useState(-1);
	const [connections, setConnections] = React.useState(null);
	const [isConnector, setIsConnector] = React.useState(true);
	const [nameRequired, setNameRequired] = React.useState(false);
	const [description, setDescription] = React.useState("");
	const [exists, setExists] = React.useState(false);
	const [editClicked, setEditClicked] = React.useState(false);
	const [isEditMode, setIsEditMode] = React.useState(false);
	const [storageType, setStorageType] = React.useState("");
	const [dataFlowRequired, setDataFlowRequired] = React.useState(false);
	const [typeRequired, setTypeRequired] = React.useState(false);
	const [connectionRequired, setConnectionRequired] = React.useState(false);
	const masterData = React.useContext(MasterDataContext);
	const typeOptions = [
		{
			value: "1",
			label: "Create Shipment",
			id: "1"
		},
		{
			value: "2",
			label: "Update Shipment",
			id: "2"
		},
		{
			value: "3",
			label: "Complete Shipment",
			id: "3"
		},
		{
			value: "4",
			label: "Process SAP Feed",
			id: "4"
		},
		{
			value: "5",
			label: "Transporter API",
			id: "5"
		}
	];
	const showToastMessage = (msg) => {
		toast.success(msg, {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "light"
		});
	};

	const showErrorMessage = (msg) => {
		toast.error(msg, {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "light"
		});
	};
	React.useEffect(() => {
		if (props.connections && props.connections.length > 0) {
			// Assuming you want to pre-select the first connection, modify as needed

			const newTypeOptions = props.connections.map((conn) => ({
				id: conn.id,
				label: conn.name,
				value: conn.id
			}));

			// Set the connection state
			if (props.selectedIntegration) {
				const Integration = props.selectedIntegration;
				const config = JSON.parse(Integration.config);
				setName(Integration.name || "");
				setDataFlow(config.dataFlow || "");
				const matchedType = typeOptions.find((option) => option.id === Integration.type.toString());
				setType(matchedType ? matchedType.value : "");
				setConnection(Integration.integration_connection__connection_id || "");
				setIsEditMode(true);
			} else {
				setName("");
				setDataFlow("");
				setType("");
				setConnection(-1);
				setIsEditMode(false);
			}
			setConnections(newTypeOptions);
		}
	}, [props, masterData.connector]);

	function validateForm() {
		let isValid = true;

		if (!name.trim()) {
			setNameRequired(true);
			isValid = false;
		} else {
			setNameRequired(false);
		}

		if (!dataFlow.trim()) {
			setDataFlowRequired(true);
			isValid = false;
		} else {
			setDataFlowRequired(false);
		}

		if (!type.trim()) {
			setTypeRequired(true);
			isValid = false;
		} else {
			setTypeRequired(false);
		}

		if (connection === -1) {
			setConnectionRequired(true);
			isValid = false;
		} else {
			setConnectionRequired(false);
		}

		return isValid;
	}

	function save() {
		if (!validateForm()) return;

		if (!isEditMode) {
			//post
			const data = {
				name: name,
				config: { dataFlow },
				type: type,
				connection_id: connection,
				cron: null,
				is_cron: 0,
				execution_freq: "TODO: Daily multiple times",
				execution_mode: 1,
				active: 1,
				description: "TODO: description of the config"
			};
			const url = dataConfigs.integrationAPI.value + "add_configurations";

			API("POST", url, data)
				.then((res) => {
					showToastMessage(res.message);
					props.onClose(true);
					props.getData();
				})
				.catch((error) => {
					showErrorMessage(error.message);
				});
		} else {
			//Data updated successfully
			//put
			const data = {
				id: props.selectedIntegration.id,
				name: name,
				config: { dataFlow },
				type: type,
				connection_id: connection,
				cron: null,
				is_cron: 0,
				execution_freq: "weekly multiple times",
				execution_mode: 1,
				active: 1,
				description: "blah blah fixed description for now"
			};

			if (storageType === "Internal") delete data.config;
			const url = dataConfigs.integrationAPI.value + "modify_configurations";

			API("PUT", url, data)
				.then((res) => {
					props.onClose(true);
					props.getData();
					showToastMessage(res.message);
				})
				.catch((error) => {
					showErrorMessage(error.message);
				});

			setDescription(null);
		}
		setExists(false);
	}

	function closePopup() {
		props.onClose(false);
	}
	async function downloadShipmentTemplate() {
		const url = BASE_URL + dataConfigs.integrationAPI.value + "get_shipmentTemplate";

		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" // Set appropriate content type for Excel
			},
			credentials: "include"
		})
			.then((res) => res.blob())
			.then((blob) => {
				const file = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.setAttribute("href", file);
				link.setAttribute("download", "shipmentTemplate.xlsx");
				link.click();
			});
	}
	return (
		<>
			<Modal
				size="s"
				isOpen={props.open}
				onClose={() => {
					closePopup();
				}}
			>
				<ModalTitle detail={<Title>{isEditMode ? "Configuration Details" : "Add Configurations"}</Title>} />
				<DialogContent>
					<Grid item xs={12} justifyContent="space between">
						<form className="config-model-form">
							<Grid container direction="column" spacing={1}>
								<Grid container item spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={6}>
										<div>
											<Typography variant="body2" alignItems="center" justifyContent="center">
												Name:
											</Typography>
										</div>
									</Grid>
									<Grid item xs={6}>
										<InputText value={name} error={nameRequired} errorMessage={nameRequired ? "Name is required" : ""} placeholder="Enter Configuration Name" required={true} defaultValue={name} onChange={(e) => setName(e.target.value)} />
									</Grid>
								</Grid>
								<Grid container item spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={6}>
										<div>
											<Typography variant="body2" alignItems="center" justifyContent="center">
												Connection:
											</Typography>
										</div>
									</Grid>
									<Grid item xs={6}>
										<Select placeholder="select connection" error={connectionRequired} errorMessage={connectionRequired ? "Connection is required" : ""} onChange={(value) => setConnection(value)} options={connections} value={connection || ""} defaultValue={connection || ""} />
									</Grid>
								</Grid>

								<Grid container item spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={6}>
										<div>
											<Typography variant="body2" alignItems="center" justifyContent="center">
												Data Flow:
											</Typography>
										</div>
									</Grid>
									<Grid item xs={6}>
										<InputText placeholder="Enter Data Flow" error={dataFlowRequired} errorMessage={dataFlowRequired ? "Data Flow is required" : ""} defaultValue={dataFlow} value={dataFlow} onChange={(e) => setDataFlow(e.target.value)} required={true} />
									</Grid>
								</Grid>
								<Grid container item spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={6}>
										<div>
											<Typography variant="body2" alignItems="center" justifyContent="center">
												Type:
											</Typography>
										</div>
									</Grid>
									<Grid item xs={6}>
										<Select placeholder="select type of configuration" disabled={!editClicked && !isConnector} onChange={(value) => setType(value)} options={typeOptions} value={type || ""} defaultValue={type || ""} />
									</Grid>
								</Grid>
							</Grid>
						</form>
					</Grid>
				</DialogContent>
				<Grid container direction="column" justifyContent="center" alignItems="center">
					<Grid item>
						<IconButton aria-label="download" onClick={downloadShipmentTemplate}>
							<CloudDownloadIcon fontSize="large" />
						</IconButton>
					</Grid>
					<Grid item>Sample file</Grid>
				</Grid>
				<DialogActions>
					<Button
						label="Close"
						variant="contained"
						size="small"
						class="rightFloat"
						onClick={() => {
							closePopup();
						}}
					/>

					<Button
						label="Save"
						variant="contained"
						size="small"
						class="rightFloat"
						onClick={() => {
							save();
						}}
					/>
				</DialogActions>
			</Modal>
			<ToastContainer />
		</>
	);
}
