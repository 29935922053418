import { AccordionDetails, AccordionSummary, Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import dataConfigs from "../../configs/dataConfigs";
import { generateParams, getQueryParamsData } from "../../core/utils.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AppThemes } from "@roambee/client-styleguide";
import "../../App.scss";
import { MasterDataContext } from "../../App";

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
  border: 1px solid #000;
`);

const StatData = styled(Box)(`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 5px
`);

const StatLabel = styled(Box)(`
  font-size: 12px;
  text-align: center;
`);

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	border: `0.5px solid ${theme.palette.divider}`,
	"&:not(:last-child)": {
		borderBottom: 0
	},
	"&:before": {
		display: "none"
	}
}));

const RightSideExpander = (props) => {
	const [expanded, setExpanded] = React.useState<string | false>(false);

	const [totalShipments, setTotalShipments] = React.useState(0);
	const [successShipments, setSuccessShipments] = React.useState(0);
	const [failedShipments, setFailedShipments] = React.useState(0);

	const [totalInputs, setTotalInputs] = React.useState(0);
	const [successInputs, setSuccessInputs] = React.useState(0);
	const [partialInputs, setPartialInputs] = React.useState(0);
	const [failedInputs, setFailedInputs] = React.useState(0);

	const [inbound, setInbound] = React.useState([]);
	const [outbound, setOutbound] = React.useState([]);
	const masterData = useContext(MasterDataContext);

	const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
		setExpanded(isExpanded ? panel : false);
	};

	useEffect(() => {
		const transactionsArgs = {
			module: "integration_transaction",
			other_options: {
				return_all: true
			},
			filters: [{ name: "integration_id", op: "eq", value: props.integrationId }]
		};

		const transactions = {
			type: transactionsArgs.module,
			data: { rbql: getQueryParamsData(transactionsArgs) }
		};

		let url = dataConfigs.CRUDAPI.value + transactionsArgs.module + "/" + generateParams(transactions.data.rbql);

		API("GET", url, {}).then((content) => {
			setTotalInputs(content.data.length);

			setFailedInputs(_.filter(content.data, { status: 0 }).length);
			setSuccessInputs(_.filter(content.data, { status: 1 }).length);
			setPartialInputs(_.filter(content.data, { status: 2 }).length);
		});

		const transactionRecordsArgs = {
			module: "integration_transaction_record",
			other_options: {
				return_all: true
			},
			filters: [
				{
					name: "integration_transaction__integration_id",
					op: "eq",
					value: props.integrationId
				}
			],
			joins: [
				{
					join_type: "left_join",
					table_name: "integration_transaction",
					left_table_attribute: "transaction_id",
					right_table_attribute: "id",
					fields: [
						{
							name: "integration_id"
						}
					]
				}
			]
		};

		const transactionRecords = {
			type: transactionRecordsArgs.module,
			data: { rbql: getQueryParamsData(transactionRecordsArgs) }
		};

		url = dataConfigs.CRUDAPI.value + transactionRecordsArgs.module + "/" + generateParams(transactionRecords.data.rbql);

		API("GET", url, {}).then((content) => {
			setTotalShipments(content.data.length);

			setFailedShipments(_.filter(content.data, { status: "0" }).length);
			setSuccessShipments(_.filter(content.data, { status: "1" }).length);
		});

		const integrationConnectionArgs = {
			module: "integration_connection",
			other_options: {
				return_all: true
			},
			filters: [{ name: "integration_id", op: "eq", value: props.integrationId }],
			joins: [
				{
					join_type: "left_join",
					table_name: "connection",
					left_table_attribute: "connection_id",
					right_table_attribute: "id",
					fields: [
						{
							name: "config"
						},
						{
							name: "type"
						},
						{
							name: "name"
						}
					]
				}
			]
		};

		const integrationConnection = {
			type: integrationConnectionArgs.module,
			data: { rbql: getQueryParamsData(integrationConnectionArgs) }
		};

		url = dataConfigs.CRUDAPI.value + integrationConnectionArgs.module + "/" + generateParams(integrationConnection.data.rbql);

		API("GET", url, {}).then((content) => {
			const integrationConnection = content.data;

			const inboundConnection = _.filter(integrationConnection, { mode: 1 });
			const outboundConnection = _.filter(integrationConnection, { mode: 2 });

			if (inboundConnection.length > 0) {
				const array = [];
				const inboundArray = [];

				inboundConnection.forEach((element) => {
					const connection_name = _.find(masterData.connector, {
						key: element.connection__type
					}).value;

					array.push({ name: "Name", value: element.connection__name });
					array.push({ name: "Type", value: connection_name });
					const config = JSON.parse(element.connection__config);
					Object.keys(config).forEach((key) => array.push({ name: key, value: config[key] }));
					inboundArray.push({ id: element.id, data: array });
				});
				setInbound(inboundArray);
			} else {
				const inboundArray = [];

				setInbound(inboundArray);
			}

			if (outboundConnection.length > 0) {
				const array = [];
				const outboundArray = [];

				outboundConnection.forEach((element) => {
					const connection_name = _.find(masterData.connector, {
						key: element.connection__type
					}).value;

					array.push({ name: "Name", value: element.connection__name });
					array.push({ name: "Type", value: connection_name });
					const config = JSON.parse(element.connection__config);
					Object.keys(config).forEach((key) => array.push({ name: key, value: config[key] }));
					outboundArray.push({ id: element.id, data: array });
				});
				setOutbound(outboundArray);
			} else {
				const outboundArray = [];

				setOutbound(outboundArray);
			}
		});
	}, [props.integrationId]);

	return (
		<div>
			<Accordion elevation={0} className="shadow topRadius" expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
				<Box sx={{ height: "25%" }}>
					<AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
						<Typography sx={{ flexShrink: 0 }}>Statistics</Typography>
					</AccordionSummary>
				</Box>
				<AccordionDetails>
					<Box component={"span"} sx={{ fontWeight: "bold", marginLeft: 1 }}>
						Shipments
					</Box>
					<Card sx={{ margin: 1 }}>
						<CardContentNoPadding>
							<Grid container>
								<Grid xs={12}>
									<Box>
										<StatData>{totalShipments}</StatData>
										<StatLabel>Total</StatLabel>
									</Box>
								</Grid>
								<Grid item xs={6}>
									<StatData>{successShipments}</StatData>
									<StatLabel>Success</StatLabel>
								</Grid>
								<Grid item xs={6}>
									<StatData>{failedShipments}</StatData>
									<StatLabel>Failure</StatLabel>
								</Grid>
							</Grid>
						</CardContentNoPadding>
					</Card>

					<Box component={"span"} sx={{ fontWeight: "bold", marginLeft: 1 }}>
						Inputs
					</Box>
					<Card sx={{ margin: 1 }}>
						<CardContentNoPadding>
							<Grid container>
								<Grid xs={12}>
									<Box>
										<StatData>{totalInputs}</StatData>
										<StatLabel>Total</StatLabel>
									</Box>
								</Grid>
								<Grid item xs={4}>
									<StatData>{successInputs}</StatData>
									<StatLabel>Success</StatLabel>
								</Grid>
								<Grid item xs={4}>
									<StatData>{partialInputs}</StatData>
									<StatLabel>Partial Success</StatLabel>
								</Grid>
								<Grid item xs={4}>
									<StatData>{failedInputs}</StatData>
									<StatLabel>Failure</StatLabel>
								</Grid>
							</Grid>
						</CardContentNoPadding>
					</Card>
				</AccordionDetails>
			</Accordion>
			<Accordion className="shadow" expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
				<Box>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
						<Typography sx={{ flexShrink: 0 }}>Inbound</Typography>
					</AccordionSummary>
				</Box>
				<AccordionDetails>
					{inbound.length ? (
						inbound.map((input) => {
							return (
								<Card>
									{input?.data.map((data, i) => {
										return (
											<Grid container sx={{ fontSize: "11px" }} key={i}>
												<Grid xs={5} padding={1} item sx={{ fontWeight: "bold" }}>
													{data.name}:
												</Grid>
												<Grid className="overflow-breakword" xs={7} padding={1} item>
													{typeof data?.value != "object" ? data?.value?.toString() : JSON.stringify(data?.value)}
												</Grid>
											</Grid>
										);
									})}
								</Card>
							);
						})
					) : (
						<Typography sx={{ flexShrink: 0 }} mt={2} align="center">
							NA
						</Typography>
					)}
				</AccordionDetails>
			</Accordion>
			<Accordion className="shadow" expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
				<Box>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
						<Typography sx={{ flexShrink: 0 }}>Mapping</Typography>
					</AccordionSummary>
				</Box>
				<AccordionDetails>
					<Typography sx={{ flexShrink: 0 }} mt={2} align="center">
						NA
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Accordion className="shadow bottomRadius" expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
				<Box>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4bh-content" id="panel4bh-header">
						<Typography sx={{ flexShrink: 0 }}>Outbound</Typography>
					</AccordionSummary>
				</Box>
				<AccordionDetails>
					{outbound.length ? (
						outbound.map((input) => {
							return (
								<Card>
									{input?.data.map((data, i) => {
										return (
											<Grid container sx={{ fontSize: "11px" }} key={i}>
												<Grid xs={5} padding={1} item sx={{ fontWeight: "bold" }}>
													{data.name}:
												</Grid>
												<Grid className="overflow-breakword" xs={7} padding={1} item>
													{typeof data?.value != "object" ? data?.value?.toString() : JSON.stringify(data?.value)}
												</Grid>
											</Grid>
										);
									})}
								</Card>
							);
						})
					) : (
						<Typography sx={{ flexShrink: 0 }} mt={2} align="center">
							NA
						</Typography>
					)}
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default RightSideExpander;
