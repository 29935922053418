import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "./connections.scss";
import { Box, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import _ from "lodash";
import dataConfigs from "../../configs/dataConfigs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button, InputText, InputTextArea, Switch, Modal, InfoDetail, Title, Select } from "@roambee/client-styleguide";
import styled from "styled-components";
import { MasterDataContext } from "../../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const linkStyle = {
	textAlign: "justify" as const,
	padding: "0"
	// marginLeft: "-16px"
};

const ModalTitle = styled(InfoDetail)`
	margin-top: 16px;
`;

const showToastMessage = (msg) => {
	toast.success(msg, {
		position: "top-right",
		autoClose: 1000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
		theme: "light"
	});
};

const showErrorMessage = (msg) => {
	toast.error(msg, {
		position: "top-right",
		autoClose: 2000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: false,
		progress: undefined,
		theme: "light"
	});
};

export default function ConnectionDetailsModal(props) {
	const [connection, setConnection] = React.useState(null);
	const [connections, setConnections] = React.useState(null);

	const [inputFields, setInputFields] = React.useState([]);
	const [isConnector, setIsConnector] = React.useState(false);
	const [name, setName] = React.useState("");
	const [nameRequired, setNameRequired] = React.useState(false);

	const [description, setDescription] = React.useState("");
	const [descriptionRequired, setDescriptionRequired] = React.useState(false);
	const [formData, setFormData] = React.useState({});
	const [checked, setChecked] = React.useState(true);
	const [exists, setExists] = React.useState(false);
	const [editClicked, setEditClicked] = React.useState(false);
	const masterData = React.useContext(MasterDataContext);
	const [storageType, setStorageType] = React.useState("Internal");
	React.useEffect(() => {
		if (props.connection) {
			props.connection.image = _.find(masterData.connector, { key: props.connection.type }).logo_path;
			props.connection.connection_description = _.find(masterData.connector, {
				key: props.connection.type
			}).description;
			props.connection.connection_name = _.find(masterData.connector, { key: props.connection.type }).value;

			setConnection(props.connection);
			setConnections(props.connections);

			if (props.connection.active && !props.connection.isConnector) {
				setChecked(true);
			} else {
				setChecked(false);
			}

			if (props.connection.isConnector == true) {
				setName(null);
			} else {
				setName(props.connection.name);
				setDescription(props.connection.description);
				const config = JSON.parse(props.connection.config);
				setStorageType(config.storageType);
			}

			const config = JSON.parse(props.connection.config);
			delete config["config"];

			let array = [];
			Object.keys(config).forEach((key) => {
				if (key !== "internal_bucket_name" && key !== "storageType" && key !== "internal_email")
					array.push({
						name: key,
						placeholder: props.connection.isConnector ? config[key]?.toString() : JSON.parse(props.connection.connector__config)[key],
						value: props.connection.isConnector ? "" : typeof config[key] != "object" ? config[key]?.toString() : JSON.stringify(config[key]),
						error: false,
						errorMessage: "Required"
					});
			});
			if (props.connection.connector__config) {
				const connectorConfig = JSON.parse(props.connection.connector__config).config;

				const fieldsArray = connectorConfig.map((item) => ({
					name: item.label,
					placeholder: item.description,
					value: item.default_value,
					error: false,
					errorMessage: item.mandatory ? "Required" : ""
				}));
				array = array.concat(fieldsArray.filter((item2) => !array.some((item1) => item1.name === item2.name)));
			}

			setInputFields(array);

			if (props.connection.isConnector) {
				setIsConnector(props.connection.isConnector);
				setEditClicked(true);
			} else {
				setEditClicked(false);
			}
		}
	}, [props.open, props.connection, props.connections]);

	function handleFormChange(index, event) {
		if (index == null) {
			setName(event.target.value.trim());
		} else {
			const data = [...inputFields];
			data[index].value = event.target.value.trim();

			setInputFields(data);

			const nameArray = inputFields.map(function (el) {
				const obj = {};
				if (el[el.name]) {
					obj[el.name] = el[el.name];
					el.value = el[el.name];
				} else {
					obj[el.name] = el.value;
				}
				return obj;
			});

			setFormData(Object.assign({}, ...nameArray));
		}
	}
	function hasEmptyValues(obj) {
		for (const key in obj) {
			if (obj[key] === "") {
				return true;
			}
		}
		return false;
	}
	function validateForm() {
		let isValid = true;
		if (!name?.trim()) {
			isValid = false;
			setNameRequired(true);
		} else {
			setNameRequired(false);
		}

		if (!description.trim()) {
			isValid = false;
			setDescriptionRequired(true);
		} else {
			setDescriptionRequired(false);
		}

		// Validate other input fields
		if (storageType !== "Internal") {
			const updatedInputFields = inputFields.map((field) => {
				if (field.type === "string" && field.value.trim() === "") {
					isValid = false;
					return { ...field, error: true };
				}
				return { ...field, error: false };
			});
			setInputFields(updatedInputFields);
		}
		return isValid;
	}
	function save() {
		if (!validateForm()) {
			return;
		}
		const newInputFields = [];

		for (let index = 0; index < inputFields.length; index++) {
			if (inputFields[index].value === "") {
				newInputFields.push({
					...inputFields[index],
					error: true
				});
			} else {
				newInputFields.push({
					...inputFields[index],
					error: false
				});
			}
		}

		setInputFields(newInputFields);

		//if (!name || _.find(newInputFields, { error: true })) return;
		const nameArray = inputFields.map(function (el) {
			const obj = {};
			if (el[el.name]) {
				obj[el.name] = el[el.name];
				el.value = el[el.name];
			} else {
				obj[el.name] = el.value;
			}
			return obj;
		});
		const newFormData = Object.assign({}, ...nameArray);
		const configData = { ...newFormData, storageType: storageType };
		if (storageType === "External" && hasEmptyValues(configData)) {
			return;
		}
		if (isConnector) {
			//post
			const data = {
				name: name,
				config: storageType === "Internal" ? { storageType: storageType } : configData,
				active: checked ? 1 : 0,
				validated: 1,
				description: description,
				type: connection.type
			};
			const url = dataConfigs.integrationAPI.value + "add_connections";

			API("POST", url, data)
				.then((res) => {
					if (res?.message.toLowerCase() === "something went wrong") {
						setExists(true);
						return;
					}
					showToastMessage(res.message);
					props.onClose(true);
					props.getData();
				})
				.catch((error) => {
					showErrorMessage(error.message);
				});
		} else {
			const data = {
				id: connection.id,
				name: name ? name : connection.name,
				active: checked ? 1 : 0,
				config: storageType === "Internal" ? { storageType: storageType } : configData,
				description: description,
				type: connection.type
			};

			const url = dataConfigs.integrationAPI.value + "modify_connections";

			API("PUT", url, data)
				.then((res) => {
					if (res?.message.toLowerCase() === "something went wrong") {
						setExists(true);
						return;
					}
					showToastMessage(res.message);
					props.onClose(true);
				})
				.catch((error) => {
					showErrorMessage(error.message);
				});
		}
		setExists(false);
	}
	function closePopup() {
		props.onClose(false);
		setNameRequired(false);
		setExists(false);
	}

	const typeOptions = [
		{
			value: "Internal",
			label: "Internal",
			id: "1"
		},

		{
			value: "External",
			label: "External",
			id: "2",
			disabled: true
		}
	];

	return (
		<>
			<Modal
				size="m"
				isOpen={props.open}
				onClose={() => {
					closePopup();
				}}
			>
				<ModalTitle detail={<Title>{isConnector ? "Add New Connection" : "Connection Details"}</Title>} />
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={5}>
							<Grid container justifyContent="center" alignItems="center" spacing={4}>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<Box display="flex" justifyContent="flex-end">
										{connection && <Box height={50} component="img" src={require(`../../assets/images/${connection?.image}`)} />}
									</Box>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<h3>{connection?.connection_name}</h3>
								</Grid>
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography variant="body2" style={linkStyle}>
										{connection?.connection_description}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={1} container alignItems="center">
							<Divider orientation="vertical" />
						</Grid>

						<Grid item xs={6}>
							<form>
								<Grid container spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={5}>
										<div>
											<Typography variant="body2" alignItems="center" justifyContent="center">
												Type:{" "}
											</Typography>
										</div>
									</Grid>

									<Grid item xs={7}>
										<Select disabled={!editClicked && !isConnector} onChange={(value) => setStorageType(value)} options={typeOptions} value={storageType} defaultValue={storageType} Required />
									</Grid>
								</Grid>

								<Grid container spacing={2} alignItems="center" justifyContent="center">
									<Grid item xs={5}>
										<div>
											<Typography variant="body2" alignItems="center" justifyContent="center">
												Name:{" "}
											</Typography>
										</div>
									</Grid>
									<Grid item xs={7}>
										<InputText disabled={!editClicked && !isConnector} name={connection?.name} placeholder={connection?.name ? "Name" : connection?.name} defaultValue={isConnector ? "" : connection?.name} error={nameRequired} errorMessage={nameRequired && "Required"} onChange={(event) => handleFormChange(null, event)} />
									</Grid>
								</Grid>

								{storageType !== "Internal" &&
									inputFields.map((input, index) => {
										return (
											<Grid container spacing={2} key={index} alignItems="center" justifyContent="center">
												<Grid item xs={5}>
													<div>
														<Typography variant="body2">{input.name}:</Typography>
													</div>
												</Grid>
												<Grid item xs={7}>
													<InputText disabled={!editClicked && !isConnector} name={input?.name} placeholder={input?.placeholder} defaultValue={isConnector ? "" : input?.value} error={input?.error} errorMessage={input?.error && input?.errorMessage} onChange={(event) => handleFormChange(index, event)} />
												</Grid>
											</Grid>
										);
									})}

								<Grid container spacing={2} alignItems="center" sx={{ mt: -1 }}>
									<Grid item xs={5}>
										<Typography variant="body2">Active: </Typography>
									</Grid>

									<Grid item xs={7}>
										<Switch
											title="Activate/Inactivate"
											checked={checked}
											disabled={!editClicked && !isConnector}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												setChecked(event.target.checked);
											}}
											sx={{ marginLeft: 1 }}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Grid>
								</Grid>

								<Grid container spacing={2} alignItems="center">
									<Grid item xs={5}>
										<Typography variant="body2">Description: </Typography>
									</Grid>
									<Grid item xs={7}>
										<InputTextArea disabled={!editClicked && !isConnector} name={connection?.description} rows={4} placeholder="Description" defaultValue={isConnector ? "" : connection?.description} onChange={(event) => setDescription(event.target.value)} error={descriptionRequired} errorMessage={setDescriptionRequired && "Required"} />
									</Grid>
								</Grid>
							</form>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						label="Close"
						variant="contained"
						size="small"
						class="rightFloat"
						onClick={() => {
							closePopup();
						}}
					/>
					{connection?.connection_name != "API" &&
						(editClicked ? (
							<Button
								label="Save"
								variant="contained"
								size="small"
								class="rightFloat"
								onClick={() => {
									save();
								}}
							/>
						) : (
							<Button
								label="Edit"
								variant="contained"
								size="small"
								class="rightFloat"
								onClick={() => {
									setEditClicked(true);
								}}
							/>
						))}
				</DialogActions>
			</Modal>
			<ToastContainer />
		</>
	);
}
