import { Grid } from "@mui/material";
import { ChartColumn, ChartLineSmooth, Download } from "@carbon/icons-react";
import styled from "styled-components";
import { CSVLink } from "react-csv";

import RightSideConnectors from "../../pages/rightsidePanel/RightSideConnectors";
import RightSideIntegration from "../../pages/rightsidePanel/RightSideIntegration";
import TransactionList from "./transactionList";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Card, Chart, Button, Body, H3, Small, AppThemes } from "@roambee/client-styleguide";
import { useState } from "react";

// styles
export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;
export const TitleContainer = styled.div``;

export const Title = styled(Body)`
	color: ${AppThemes.light.colors.neutral900};
`;

export const Bold = styled(Body).attrs({ as: "span" })`
	font-weight: bold;
`;

export const MainValue = styled(H3)`
	margin-block-start: 0.5rem;
	display: flex;
	justify-content: space-between;
`;

export const Description = styled(Small)`
	color: ${AppThemes.light.colors.neutral600};
`;
export const ButtonContainer = styled.div``;
export const IconButton = styled(Button)`
	gap: 0;
	height: 0;
	padding-inline: 0;
	margin-left: 10px;
`;

// interface
interface chartData {
	timestamp: Date;
	success: number;
	failed: number;
}

// default component
const IntegrationHubDashboard = () => {
	// const [shipmentChartType, setShipmentChartType] = useState("line");
	// const [fileChartType, setFileChartType] = useState("line");

	// const shipmentChartData = {
	// 	total: 486,
	// 	successPercentage: 83,
	// 	data: [
	// 		{ timestamp: 1684843035000, success: 50, failed: 5 }, // timestamp in miliseconds
	// 		{ timestamp: 1684844035000, success: 35, failed: 8 },
	// 		{ timestamp: 1684845035000, success: 60, failed: 12 },
	// 		{ timestamp: 1684846035000, success: 44, failed: 1 },
	// 		{ timestamp: 1684847035000, success: 78, failed: 13 },
	// 		{ timestamp: 1684848035000, success: 40, failed: 5 },
	// 		{ timestamp: 1684849035000, success: 65, failed: 8 },
	// 		{ timestamp: 1684851907000, success: 43, failed: 2 },
	// 		{ timestamp: 1684853907000, success: 78, failed: 19 },
	// 		{ timestamp: 1684855907000, success: 48, failed: 12 },
	// 		{ timestamp: 1684858035000, success: 60, failed: 5 },
	// 		{ timestamp: 1684860035000, success: 75, failed: 8 },
	// 		{ timestamp: 1684862035000, success: 40, failed: 6 },
	// 		{ timestamp: 1684863035000, success: 84, failed: 13 },
	// 		{ timestamp: 1684865035000, success: 38, failed: 9 },
	// 		{ timestamp: 1684868035000, success: 80, failed: 15 },
	// 		{ timestamp: 1684869035000, success: 45, failed: 3 },
	// 		{ timestamp: 1684871907000, success: 53, failed: 8 },
	// 		{ timestamp: 1684873907000, success: 47, failed: 5 },
	// 		{ timestamp: 1684875907000, success: 38, failed: 12 }
	// 	]
	// };

	// const fileChartData = {
	// 	total: 326,
	// 	successPercentage: 78,
	// 	data: [
	// 		{ timestamp: 1684843035000, success: 10, failed: 5 }, // timestamp in miliseconds
	// 		{ timestamp: 1684844035000, success: 15, failed: 8 },
	// 		{ timestamp: 1684845035000, success: 20, failed: 2 },
	// 		{ timestamp: 1684846035000, success: 14, failed: 1 },
	// 		{ timestamp: 1684847035000, success: 18, failed: 12 },
	// 		{ timestamp: 1684848035000, success: 20, failed: 5 },
	// 		{ timestamp: 1684849035000, success: 15, failed: 8 },
	// 		{ timestamp: 1684851907000, success: 23, failed: 2 },
	// 		{ timestamp: 1684853907000, success: 14, failed: 3 },
	// 		{ timestamp: 1684855907000, success: 38, failed: 12 },
	// 		{ timestamp: 1684858035000, success: 10, failed: 5 },
	// 		{ timestamp: 1684860035000, success: 15, failed: 8 },
	// 		{ timestamp: 1684862035000, success: 20, failed: 2 },
	// 		{ timestamp: 1684863035000, success: 14, failed: 1 },
	// 		{ timestamp: 1684865035000, success: 28, failed: 12 },
	// 		{ timestamp: 1684868035000, success: 10, failed: 5 },
	// 		{ timestamp: 1684869035000, success: 15, failed: 8 },
	// 		{ timestamp: 1684871907000, success: 23, failed: 2 },
	// 		{ timestamp: 1684873907000, success: 14, failed: 1 },
	// 		{ timestamp: 1684875907000, success: 28, failed: 12 }
	// 	]
	// };

	return (
		// row
		<Grid container spacing={2}>
			{/* columns */}
			<Grid item xs={9}>
				{/* row */}
				<Grid container spacing={2}>
					{/* add column here for chart having xs={12} */}
					{/* column */}
					{/* <Grid item xs={6}>
						<Card>
							<Header>
								<TitleContainer>
									<Title $bold>Total Shipments</Title>
									<MainValue>{shipmentChartData.total}</MainValue>
									<Description>{shipmentChartData.successPercentage}% successfull</Description>
								</TitleContainer>
								<ButtonContainer>
									{shipmentChartType === "line" ? (
										<IconButton
											icon={<ChartColumn />}
											variant="no-bg"
											onClick={() => {
												setShipmentChartType("bar");
											}}
										></IconButton>
									) : (
										<IconButton
											icon={<ChartLineSmooth />}
											variant="no-bg"
											onClick={() => {
												setShipmentChartType("line");
											}}
										></IconButton>
									)}
									<CSVLink data={shipmentChartData.data} filename="shipment_chart_data.csv">
										<IconButton icon={<Download />} variant="no-bg"></IconButton>
									</CSVLink>
								</ButtonContainer>
							</Header>
							<Chart<chartData>
								data={shipmentChartData.data}
								xDataKey="timestamp"
								yDataKeys={["success", "failed"]}
								xDataType="time"
								chartType={shipmentChartType}
							/>
						</Card>
					</Grid>
					<Grid item xs={6}>
						<Card>
							<Header>
								<TitleContainer>
									<Title $bold>Total Files</Title>
									<MainValue>{fileChartData.total}</MainValue>
									<Description>{fileChartData.successPercentage}% successfull</Description>
								</TitleContainer>
								<ButtonContainer>
									{fileChartType === "line" ? (
										<IconButton
											icon={<ChartColumn />}
											variant="no-bg"
											onClick={() => {
												setFileChartType("bar");
											}}
										></IconButton>
									) : (
										<IconButton
											icon={<ChartLineSmooth />}
											variant="no-bg"
											onClick={() => {
												setFileChartType("line");
											}}
										></IconButton>
									)}
									<CSVLink data={fileChartData.data} filename="file_chart_data.csv">
										<IconButton icon={<Download />} variant="no-bg"></IconButton>
									</CSVLink>
								</ButtonContainer>
							</Header>
							<Chart<chartData>
								data={fileChartData.data}
								xDataKey="timestamp"
								yDataKeys={["success", "failed"]}
								xDataType="time"
								chartType={fileChartType}
							/>
						</Card>
					</Grid> */}
					<Grid item xs={12}>
						<TransactionList></TransactionList>
					</Grid>
				</Grid>
			</Grid>
			{/* columns */}
			<Grid item xs={3}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<RightSideIntegration />
					</Grid>
					<Grid item xs={12}>
						<RightSideConnectors />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default IntegrationHubDashboard;
