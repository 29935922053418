import { useContext, useEffect, useState } from "react";
import dataConfigs from "../../configs/dataConfigs";
import queryParamsConfig from "../../configs/queryParamsConfig";
// import { API, getQueryParamsData } from "../../core/API";
import _ from "lodash";
import ConnectionDetailsModal from "./connectionDetailsModal";
import { CardContentContainer, TagContainer } from "../../pages/rightsidePanel/styles";
import { styled } from "@mui/system";

import { getQueryParamsData, generateParams } from "../../core/utils.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AppThemes } from "@roambee/client-styleguide";
import { MasterDataContext } from "../../App";
import { Skeleton } from "@mui/material";

const ContentContainer = styled(CardContentContainer)`
	display: inline-flex;
	border-block-start: none;
	padding-block: 0.5rem;
	width: 28%;
	padding-left: 2.07rem;
	padding-block-start: 1rem;
`;

const ConnectorTag = styled(TagContainer)`
	margin-block-end: 1rem;
	box-shadow: ${AppThemes.light.shadows.shadow8};
	border: none;
	cursor: pointer;
	&:focus-within,
	&:hover {
		background-color: ${AppThemes.light.colors.neutral100};
	}
`;

const Connectors = (props) => {
	const [connectorList, setConnectorList] = useState([]);
	const [connector, setConnector] = useState(null);

	const [open, setOpen] = useState(false);
	const masterData = useContext(MasterDataContext);

	const handleClickOpen = (_connector) => {
		setOpen(true);
		setConnector(_connector);
	};

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const args = queryParamsConfig.getConnectors;

		const opts = {
			type: args.module,
			data: { rbql: getQueryParamsData(args) }
		};

		const url = dataConfigs.CRUDAPI.value + args.module + "/" + generateParams(opts.data.rbql);

		API("GET", url, opts).then((content) => {
			content.data.forEach((item) => {
				item.image = _.find(masterData.connector, { key: item.type })?.logo_path;
				item.isConnector = true;
			});
			setConnectorList(content.data);
		});
	}, []);

	return (
		<>
			<ContentContainer>
				{connectorList.length > 0 ? (
					connectorList.map((data, index) => {
						return (
							<ConnectorTag
								onClick={() => handleClickOpen(data)}
								key={index}
								$width={74}
								$height={50}
								color={AppThemes.light.colors.neutral900}
								backgroundColor={AppThemes.light.colors.white}
								label={
									data &&
									data.image && (
										<img src={require(`../../assets/images/${data.image}`)} alt="demo" height={30} loading="lazy" />
									)
								}
							/>
						);
					})
				) : (
					<Skeleton
						sx={{
							height: "50px",
							width: "20%",
							mb: 2,
							bgcolor: "${AppThemes.light.colors.neutral200}",
							transform: "none"
						}}
						animation="wave"
					/>
				)}
			</ContentContainer>

			<ConnectionDetailsModal
				getData={(e) => props.getData()}
				connection={connector}
				connections={props.connections}
				open={open}
				onClose={() => {
					handleClose();
				}}
			/>
		</>
	);
};

export default Connectors;
