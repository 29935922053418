import { ReactNode } from "react";
import { Route } from "react-router-dom";
import appRoutes from "./appRoute";
import { RouteType } from "./config";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { setRoutes } from "@roambee/client-utility";

const generateRoute = (routes: RouteType[]): ReactNode => {
	// set routes so that sidenav mfe can use it
	setRoutes(appRoutes);

	return routes.map((route, index) => (
		<Route path={route.path} element={route.element} key={index}>
			{route.child && generateRoute(route.child)}
		</Route>
	));
};

export const routes: ReactNode = generateRoute(appRoutes);
