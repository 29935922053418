import { generateParams, getQueryParamsData } from "../core/utils.js";
import dataConfigs from "../configs/dataConfigs";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
import { useEffect, useState } from "react";

const useMasterData = (val) => {
	const [data, setData] = useState(null);

	useEffect(() => {
		const args = val;

		const opts = {
			type: args.module,
			data: { rbql: getQueryParamsData(args) }
		};

		const url = dataConfigs.CRUDAPI.value + args.module + "/" + generateParams(opts.data.rbql);
		API("GET", url, {}).then((content) => {
			setData(content.data);
		});
	}, []);

	return [data];
};

export default useMasterData;
