// const bull = <Box component="span" sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}></Box>;

import styled, { css } from "styled-components";
import { Grid, Skeleton, Switch, Tooltip } from "@mui/material";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Tag, Body, Button, InfoboxCard, InfoDetail, RowResults, Pagination } from "@roambee/client-styleguide";
import "../App.scss";
import dataConfigs from "../configs/dataConfigs";
import _ from "lodash";

import { useContext, useEffect, useState } from "react";
import queryParamsConfig from "../configs/queryParamsConfig";
import RightSideExpander from "../pages/rightsidePanel/RightSideExpander";
import RightSideConnectors from "../pages/rightsidePanel/RightSideConnectors";
import { convertDate, generateParams, getQueryParamsData } from "../core/utils.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Heading } from "../pages/rightsidePanel/styles";

import { RequestQuote, TaskView, DocumentMultiple_01 } from "@carbon/icons-react";
import { MasterDataContext } from "../App";
import IntegrationConfigModel from "./IntegrationConfigModel";

const ConfigurationList = styled(InfoboxCard)`
	width: 100%;
	border-radius: 0.5rem !important;
`;

const ListHeading = styled(Heading)`
	margin-bottom: 0;
`;

const InlineButton = styled(Button).attrs({ variant: "no-bg" })`
	padding-inline: 0;
	padding: 0;
	height: inherit;
	color: #0171a3;
	vertical-align: middle;
	// margin-left: 10px;
`;

const RequestQuoteButton = styled(Button).attrs({ variant: "no-bg" })`
	padding-inline: 0;
	padding: 0;
	height: inherit;
	color: #0171a3;
	vertical-align: middle;
	margin-left: 20px;
`;

const TaskViewButton = styled(Button).attrs({ variant: "no-bg" })`
	padding-inline: 0;
	padding: 0;
	height: inherit;
	color: #0171a3;
	vertical-align: middle;
	margin-left: 20px;
`;

const TransactionButton = styled(Button).attrs({ variant: "no-bg" })`
	padding-inline: 0;
	padding: 0;
	height: inherit;
	color: #0171a3;
	vertical-align: middle;
	margin-left: 20px;
`;

export const TagContainer = styled(Tag)`
	margin-top: -3px;
	border: 1px solid #cfced7;
	${({ $width }) =>
		$width &&
		css`
			width: ${$width}px;
		`}
	${({ $height }) =>
		$height &&
		css`
			height: ${$height}px;
		`}
	& > p {
		display: flex;
		justify-content: center;
		align-items: center;
		& > span {
			margin-left: 5px;
		}
	}
`;

const NavigationWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-inline: 2rem;
	margin-block-end: 1.5rem;
	padding: 0.5rem;
	margin-bottom: 10px;
	margin-top: -5px;
`;

const IntegrationConfigurationsList = () => {
	const navigate = useNavigate();

	const [integration, setIntegration] = useState([]);
	const [selectedId, setSelectedId] = useState(0);
	const [shownResults, setShownResults] = useState(3);
	const [selectedIntegration, setSelectedIntegration] = useState(null);
	const [tableData, setTableData] = useState([]);
	const [page, setPage] = useState(1);
	const [connections, setConnections] = useState(null);
	const currentDate = moment().unix();
	const totalPages = Math.ceil(integration.length / shownResults);
	const masterData = useContext(MasterDataContext);
	const [open, setOpen] = useState(false);
	function fetchTableData(page, shownResults) {
		const index = (page - 1) * shownResults;
		setTableData(integration.map((d) => d).splice(index, shownResults));
	}

	const showClick = (e) => {
		setSelectedId(e);
	};

	const navigateToDashboard = (data) => {
		navigate("/integrationhub/dashboard/" + data);
	};

	useEffect(() => {
		getData();
		fetchConnections();
	}, []);

	const getData = () => {
		const args = queryParamsConfig.getIntegrationList;

		const opts = {
			type: args.module,
			data: { rbql: getQueryParamsData(args) }
		};

		const url = dataConfigs.CRUDAPI.value + args.module + "/" + generateParams(opts.data.rbql);

		API("GET", url, {}).then((content) => {
			let integrationsList = [];
			try {
				content.data.forEach((item) => {
					item.createdDate = convertDate(item.created_date);
					item.modifiedDate = item.updated_date ? convertDate(item.updated_date) : null;
					item.image = item.connection__type
						? _.find(masterData.connector, {
								key: item.connection__type
						  })?.logo_path
						: null;
					item.integrationType = _.find(masterData.integrationType, { key: item.type })?.value;

					item.mode = _.find(dataConfigs.ModeValues, {
						key: item.integration_connection__mode
					})?.logo;

					integrationsList.push(item);
				});

				integrationsList = _.chain(integrationsList)
					.groupBy("id")
					.map((value, key) => ({ id: key, integrations: value }))
					.value();
				setIntegration(integrationsList || []);
				setSelectedId(integrationsList[0].id);
				setTableData(integrationsList.map((d) => d).splice(0, shownResults));
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log("err", error);
			}
		});
	};

	function fetchConnections() {
		const args = queryParamsConfig.getConnectionList;
		// Construct the API endpoint URL using predefined configurations
		const url = dataConfigs.CRUDAPI.value + args.module + "/" + generateParams(getQueryParamsData(args));

		API("GET", url, {}).then((content) => {
			// Process each connection item
			const processedConnections = content.data.map((item) => {
				return {
					...item,
					typeName: _.find(masterData.connector, { key: item.type }).value,
					typeLogo: _.find(masterData.connector, { key: item.type }).logo_path,
					activeStatus: item.active == 1 ? "Active" : "InActive",
					createdDate: convertDate(item.created_date),
					updatedDate: item.updated_date ? convertDate(item.updated_date) : null,
					createdBy: item.users__first_name + " " + item.users__last_name
				};
			});

			// Update the state with the processed connections data
			setConnections(processedConnections);
		});
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>, obj) => {
		let check = 0;
		if (event.target.checked == true) {
			check = 1;
		}

		const newArr = [...integration];
		const i = _.findIndex(newArr, { id: obj.id.toString() });

		newArr[i].integrations[0].active = check;
		setIntegration(newArr);

		const data = {
			data: {
				id: obj.id,
				active: check,
				update_date: currentDate
			}
		};

		const url = dataConfigs.CRUDAPI.value + "integration";

		API("PUT", url, data).then();
	};
	function handleModalClose() {
		setOpen(false);
		setSelectedIntegration(null);
	}

	function handleEdit(data) {
		setSelectedIntegration(data);
		setOpen(true);
	}
	return (
		<>
			<div>
				{connections && open && (
					<IntegrationConfigModel
						open={true} // This should be a state variable controlling the visibility of the modal
						onClose={handleModalClose}
						connections={connections}
						selectedIntegration={selectedIntegration}
						getData={getData}
					/>
				)}
				<Button
					label="+ Add"
					variant="contained"
					size="small"
					class="rightFloat"
					onClick={() => {
						// 	setEditClicked(true);
						setOpen(true);
					}}
				/>
			</div>
			<Grid role="presentation" container spacing={2}>
				<Grid item xs={9}>
					<NavigationWrapper>
						<RowResults
							totalResults={integration.length}
							shownResults={shownResults}
							current={page}
							onChangeshownResults={(shownResults) => {
								setShownResults(shownResults);
								fetchTableData(page, shownResults);
							}}
						/>

						<Pagination
							current={page}
							total={totalPages}
							showShortcuts={true}
							active={false}
							onChangePage={(page: number) => {
								setPage(page);
								fetchTableData(page, shownResults);
							}}
						/>
					</NavigationWrapper>
					{tableData.length > 0 ? (
						tableData.map((data, index) => {
							return (
								<div role="presentation" className="pointer" onClick={() => showClick(data?.integrations[0].id)}>
									<ConfigurationList
										key={index}
										cols={3}
										className={data?.integrations[0].id == selectedId ? "yellow" : "white"}
										title={
											<ListHeading>
												<Body $bold>
													{data?.integrations[0].name} &nbsp;
													<TagContainer label={data?.integrations[0].accounts__name} className={data?.integrations[0].id == selectedId ? "yellow" : "white"} small={true} />
													&nbsp;
													{data?.integrations.map((item, i) => {
														return (
															item.image && (
																<TagContainer
																	key={i}
																	label={
																		<>
																			<img src={require(`../assets/images/${item.image}`)} height={15} alt="" />
																			&nbsp;
																			{item.mode}
																		</>
																	}
																	className={data?.integrations[0].id == selectedId ? "yellow" : "white"}
																	small={true}
																/>
															)
														);
													})}
												</Body>
												<div className="textRight">
													Activate <InlineButton label={<Switch sx={{ mt: "-7px" }} size="small" title="Activate/Inactivate Integration" checked={data?.integrations[0].active} onChange={(e) => handleChange(e, data?.integrations[0])} inputProps={{ "aria-label": "controlled" }} />} />
													<RequestQuoteButton
														label={
															<Tooltip
																title="Edit Integration"
																onClick={() => {
																	handleEdit(data?.integrations[0]);
																}}
															>
																<RequestQuote size={24} />
															</Tooltip>
														}
													/>
													<TaskViewButton
														label={
															<Tooltip title="Audit">
																<TaskView size={24} />
															</Tooltip>
														}
													/>
													<TransactionButton
														title="Transactions"
														onClick={() => navigateToDashboard(data?.integrations[0].id)}
														label={
															<Tooltip title="Transactions">
																<DocumentMultiple_01 size={24} />
															</Tooltip>
														}
													/>
												</div>
											</ListHeading>
										}
									>
										<InfoDetail data="Created: " detail={data?.integrations[0].createdDate} />
										<InfoDetail
											data="Created by: "
											detail={
												<>
													{data?.integrations[0].users__first_name}
													&nbsp;
													{data?.integrations[0].users__last_name}
												</>
											}
										/>
										<InfoDetail data="Integration Type: " detail={data?.integrations[0].integrationType} />
										<InfoDetail data="Modified: " detail={data?.integrations[0].modifiedDate} />
										<InfoDetail
											data="Modified by: "
											detail={
												<>
													{data?.integrations[0].users_updated_by__first_name}
													&nbsp;
													{data?.integrations[0].users_updated_by__last_name}
												</>
											}
										/>
										<InfoDetail data="Frequency: " detail={data?.integrations[0].execution_freq} />
									</ConfigurationList>
								</div>
							);
						})
					) : (
						<Skeleton
							sx={{
								height: "185px",
								width: "100%",
								mb: 2,
								bgcolor: "${AppThemes.light.colors.neutral200}",
								transform: "none"
							}}
							animation="wave"
						/>
					)}
				</Grid>

				<Grid item xs={3}>
					{<RightSideExpander integrationId={selectedId} />}
					&nbsp;
					<RightSideConnectors />
				</Grid>
			</Grid>
		</>
	);
};

export default IntegrationConfigurationsList;
