/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GlobalSearch } from "@roambee/client-styleguide";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import appRoutes from "../core/appRoute";

import styled from "styled-components";
// import * as user from "./../utility/user";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";

const Divider = styled.hr`
	border: 0;
	border-top: 1px solid #d5d4dc;
	margin: 0 24px;
	margin-bottom: 20px;
`;

const Topbar = ({ user }) => {
	// get active route object for title
	const currentPath = useLocation().pathname;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const routeObj = appRoutes.find((route: any) => matchPath(route, currentPath));

	const navigate = useNavigate();

	const menuItems = [
		{
			lable: "Logout",
			clickHandler: () => {
				// logout from server
				API("POST", "/auth/logout", {}).then(() => {
					navigate("/auth");
					// logout from keycloak
					// const protocol = window.location.protocol;
					// const host = window.location.host;
					// window.location.href = `${process.env.CLIENT_KEYCLOAK_URL}/realms/${process.env.CLIENT_KEYCLOAK_REALM}/protocol/openid-connect/logout?post_logout_redirect_uri=${protocol}//${host}&client_id=${process.env.CLIENT_KEYCLOAK_CLIENTID}`;
				});
			}
		}
	];

	return (
		<>
			<GlobalSearch id="1" initial={user?.email ? user?.email[0] : ""} name="IEO Ltd Global" showSearch={false} showNotification={false} placeholder="Search" quick={["Last 10 newly created shipments", "See alert messages"]} recent={["Pharma-0114", "Vaccine-3881"]} data={["Dublin", "Madrid", "London", "Lisboa", "Rome", "Paris", "Berlin", "Sofia"]} title={routeObj.title} menuItems={menuItems} />
			<Divider></Divider>
		</>
	);
};

export default Topbar;
