import { Box, Card, CardContent, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import dataConfigs from "../../configs/dataConfigs";
import "../../App.scss";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getQueryParamsData, generateParams } from "../../core/utils.js";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@roambee/client-utility";
// import { API } from "../../core/API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Button, Tag, Table, AppThemes, InputSearch, InfoboxCard, InfoDetail, ListCard } from "@roambee/client-styleguide";
import styled from "styled-components";
import { InputOutputDataContainer, Filename, TransactionDetailContainer } from "../../pages/rightsidePanel/styles";
import { Download } from "@carbon/icons-react";
import { MasterDataContext } from "../../App";

const linkStyle = {
	color: "black",
	cursor: "pointer",
	textDecoration: "underline"
};

const DetailCard = styled(InfoboxCard)`
	width: 100%;
	border-radius: 0.5rem !important;
`;

const TotalCard = styled(ListCard)`
	height: 11.2rem;
	border-radius: 0.5rem !important;
	max-width: none;
	width: 100%;
`;

export const Search = styled(InputSearch)`
	width: 100%;
`;

export const StatusTag = styled(Tag)`
	margin: 0.25rem;
`;

const TransactionDetail = () => {
	const [data, setData] = useState({});
	const [transactionRecord, setTransactionRecord] = useState([]);
	const [totalShipments, setTotalShipments] = useState(0);
	const [successShipments, setSuccessShipments] = useState(0);
	const [failedShipments, setFailedShipments] = useState(0);
	const [inputHover, setInputHover] = useState(0);
	const [outputHover, setOutputHover] = useState(0);
	const [tableData, setTableData] = useState([]);
	const [tableEvent, setTableEvent] = useState<any>({
		page: 1,
		pageSize: 5,
		search: [],
		sort: {}
	});
	const [total, setTotal] = useState<number>();
	const [isLoading, setIsLoading] = useState(true);
	const masterData = useContext(MasterDataContext);
	const { id } = useParams();

	const downloadFile = (transaction, folder) => {
		const url = dataConfigs.fileDownloadAPI.value + "integration_hub=true" + "&filename=" + (folder === "original" ? transaction.input_filename : transaction.error_filename) + "&account_id=" + transaction.account_id + "&integration_hub_id=" + transaction.integration_id + "&folder=" + folder;

		API("GET", url, {}).then((content) => {
			if (content.indexOf('{"statusCode":404,') > -1) {
				showErrorMessage("File not found");
			} else {
				const excelBlob = new Blob([content.toString()], {
					type: "application/octet-stream"
				});
				downloadBlobExcel(excelBlob, folder === "original" ? transaction.input_filename : transaction.error_filename);
			}
		});
	};

	function downloadBlobExcel(blob, key) {
		// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
		const blobUrl = URL.createObjectURL(blob);
		// var excel = key.substring(key.lastIndexOf("/") + 1);

		const link = document.createElement("a");
		// Set link's href to point to the Blob URL
		link.href = blobUrl;
		link.download = key;
		// Append link to the body
		document.body.appendChild(link);
		// Dispatch click event on the link
		// This is necessary as link.click() does not work on the latest firefox
		link.dispatchEvent(
			new MouseEvent("click", {
				bubbles: true,
				cancelable: true,
				view: window
			})
		);

		// Remove link from body
		document.body.removeChild(link);
	}

	const showToastMessage = () => {
		toast.success("Successfully copied!", {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "light"
		});
	};

	const showErrorMessage = (msg) => {
		toast.error(msg, {
			position: "top-right",
			autoClose: 1000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			theme: "light"
		});
	};

	const columns = [
		{
			field: "status",
			headerName: "Status",
			width: 9,
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => {
				return <StatusTag label={params?.row.statusName} color={params?.row?.tagColor} backgroundColor={params?.row?.backgroundTagColor} />;
			},
			searchType: {
				type: "selectbox",
				options: dataConfigs.TransactionStatus,
				operator: "eq"
			}
		},
		{
			field: "shipment_uuid",
			headerName: "Shipment Details",
			width: 8.5,
			isSortable: true,
			isSearchable: false,
			renderCell: (params) =>
				params.row.shipment_uuid ? (
					<a className="font875rem" href={params.row.shipmentlink} target="_blank">
						View
					</a>
				) : (
					<p className="font875rem">N/A</p>
				)
		},
		{
			field: "failure_reason",
			headerName: "Details",
			width: 19,
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => (
				<>
					<div onMouseEnter={() => setInputHover(params.row.id)} onMouseLeave={() => setInputHover(0)}>
						<Grid container className="alignCenter">
							<Grid item xs={11}>
								<InputOutputDataContainer>
									<span title={params.row.failure_reason}>{params.row.failure_reason}</span>
								</InputOutputDataContainer>
							</Grid>
							<Grid item xs={1}>
								{inputHover == params.row.id && params.row.failure_reason ? (
									<IconButton
										onClick={() => {
											showToastMessage();
											navigator.clipboard.writeText(params.row.failure_reason);
										}}
									>
										<ContentCopyIcon fontSize="small" />
									</IconButton>
								) : (
									""
								)}
							</Grid>
						</Grid>
					</div>
				</>
			)
		},
		{
			field: "input_data",
			headerName: "Input Data",
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => (
				<>
					<div onMouseEnter={() => setInputHover(params.row.id)} onMouseLeave={() => setInputHover(0)}>
						<Grid container className="alignCenter">
							<Grid item xs={11}>
								<InputOutputDataContainer>
									<span title={params.row.input_data}>{params.row.input_data}</span>
								</InputOutputDataContainer>
							</Grid>
							<Grid item xs={1}>
								{inputHover == params.row.id && params.row.input_data ? (
									<IconButton
										onClick={() => {
											showToastMessage();
											navigator.clipboard.writeText(params.row.input_data);
										}}
									>
										<ContentCopyIcon fontSize="small" />
									</IconButton>
								) : (
									""
								)}
							</Grid>
						</Grid>
					</div>
				</>
			),

			width: 33
		},
		{
			field: "output_data",
			headerName: "Output Data",
			isSortable: true,
			isSearchable: true,
			renderCell: (params) => (
				<>
					<div onMouseEnter={() => setOutputHover(params.row.id)} onMouseLeave={() => setOutputHover(0)}>
						<Grid container className="alignCenter">
							<Grid item xs={10}>
								<InputOutputDataContainer>
									<span title={params.row.output_data}>{params.row.output_data}</span>
								</InputOutputDataContainer>
							</Grid>
							<Grid item xs={2}>
								{outputHover == params.row.id && params.row.output_data ? (
									<IconButton
										onClick={() => {
											navigator.clipboard.writeText(params.row.output_data);
										}}
									>
										<ContentCopyIcon fontSize="small" />
									</IconButton>
								) : (
									""
								)}
							</Grid>
						</Grid>
					</div>
				</>
			),
			width: 7
		}
	];

	useEffect(() => {
		getData(tableEvent);

		let url = "";

		const integrationTransactionArgs = {
			module: "integration_transaction",
			sort: [{ order: "desc", name: "created_date" }],
			filters: [{ name: "id", op: "eq", value: id }],
			joins: [
				{
					join_type: "left_join",
					table_name: "integration",
					left_table_attribute: "integration_id",
					right_table_attribute: "id",
					fields: [
						{
							name: "name"
						},
						{
							name: "type"
						}
					]
				},
				{
					join_type: "left_join",
					table_name: "accounts",
					left_table_attribute: "account_id",
					right_table_attribute: "id",
					fields: [
						{
							name: "name"
						}
					]
				},
				{
					join_type: "left_join",
					table_name: "users",
					left_table_attribute: "resolved_by",
					right_table_attribute: "id",
					fields: [
						{
							name: "email"
						}
					]
				}
			]
		};

		const integrationTransaction = {
			type: integrationTransactionArgs.module,
			data: { rbql: getQueryParamsData(integrationTransactionArgs) }
		};

		url = dataConfigs.CRUDAPI.value + integrationTransactionArgs.module + "/" + generateParams(integrationTransaction.data.rbql);

		API("GET", url, {}).then((content) => {
			content.data = content.data || [];
			content.data.forEach((item) => {
				item.statusName = _.find(dataConfigs.TransactionStatus, {
					key: item.status
				}).value;
				switch (item.statusName) {
					case "Success":
						item.className = "transactionSuccess";
						break;
					case "Failed":
						item.className = "transactionFailed";
						break;
					case "Partial":
						item.className = "transactionPartial";
						break;
					case "Resolved":
						item.className = "transactionResolved";
						break;
				}
				item.createdDate = moment(item.created_date * 1000).format("DD-MM-yyyy HH:mm");
				item.resolvedDate = item.resolved_date ? moment(item.resolved_date * 1000).format("DD-MM-yyyy HH:mm") : null;
				// item.typeName = _.find(dataConfigs.ShipementType, {
				// 	key: item.integration__type
				// }).value;
				item.input_filename = item.input_filename ? item.input_filename : "N/A";
				item.typeName = _.find(masterData.integrationType, {
					key: item.integration__type
				})?.value;
			});

			setData(content.data[0]);

			setTotalShipments(content.data[0]?.record_count);
			setFailedShipments(content.data[0]?.failure_count);
			setSuccessShipments(content.data[0]?.success_count);
		});
	}, [id]);

	function getData(event = null) {
		if (!event) event = tableEvent;

		const transactionRecordsArgs: any = {
			module: "integration_transaction_record",
			pagination: { page_size: event.pageSize, page_num: event.page || 1 },
			filters:
				event?.search.length > 0
					? event?.search.map((search) => ({
							name: search.field,
							op: search.operator,
							value: search.value
					  }))
					: [],
			sort: [
				{
					order: (event?.sort?.status && event?.sort?.status != "default" && (event?.sort?.status == "descendent" ? "desc" : "asc")) || "desc",
					name: event?.sort?.field && event?.sort?.status != "default" ? event?.sort?.field : "created_date"
				}
			]
		};

		transactionRecordsArgs.filters = transactionRecordsArgs.filters.concat({
			name: "transaction_id",
			op: "eq",
			value: id
		});

		const transactionRecords = {
			type: transactionRecordsArgs.module,
			data: { rbql: getQueryParamsData(transactionRecordsArgs) }
		};

		const url = dataConfigs.CRUDAPI.value + transactionRecordsArgs.module + "/" + generateParams(transactionRecords.data.rbql);

		API("GET", url, transactionRecords).then((content) => {
			//console.log('transactionRecords ', content.data);
			if (content.total == 0) {
				setIsLoading(false);
				// return;
			}
			content.data.forEach((item) => {
				item.shipmentlink = process.env.CLIENT_HC10_URL + "/shipments/details/" + item.shipment_uuid;
				item.statusName = _.find(dataConfigs.TransactionStatus, {
					key: parseInt(item.status)
				}).value;
				switch (item.statusName) {
					case "Success":
						item["tagColor"] = AppThemes.light.colors.supportSuccess800;
						item["backgroundTagColor"] = AppThemes.light.colors.supportSuccess50;
						break;
					case "Failed":
						item["tagColor"] = AppThemes.light.colors.supportError800;
						item["backgroundTagColor"] = AppThemes.light.colors.supportError50;
						break;
					case "Partial Success":
						item["tagColor"] = AppThemes.light.colors.yellowBee800;
						item["backgroundTagColor"] = AppThemes.light.colors.yellowBee50;
						break;
					case "Resolved":
						item["tagColor"] = AppThemes.light.colors.purpleSunset800;
						item["backgroundTagColor"] = AppThemes.light.colors.purpleSunset50;
						break;
				}
			});

			setTransactionRecord(content.data);
			setTableData(content.data);

			setTotal(content.total);
		});
	}

	const handleTableEvent = (event) => {
		setTableEvent(event);
		getData(event);
	};

	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					{Object.keys(data).length > 0 ? (
						<TotalCard
							info={[
								{
									description: "Success",
									value: successShipments
								},
								{
									description: "Failure",
									value: failedShipments
								}
							]}
							percentage={totalShipments}
							title="Total Shipments"
						/>
					) : (
						<Skeleton
							sx={{
								height: "11rem",
								width: "100%",
								transform: "none",
								bgcolor: "${AppThemes.light.colors.neutral200}"
							}}
							animation="wave"
						/>
					)}
				</Grid>
				<Grid item xs={9}>
					{Object.keys(data).length > 0 ? (
						<DetailCard cols={4} title={data && data["integration__name"]}>
							{/* <InfoDetail data="Shipments" detail="10.09.2021 / 16:40" /> */}
							<InfoDetail data="Account name:" detail={data && data["accounts__name"]} />
							<InfoDetail data="Integration type:" detail={data && data["typeName"]} />
							<InfoDetail
								data="Incoming Filename:"
								detail={
									<Filename>
										{data["input_filename"] != "N/A" ? (
											<span style={linkStyle} title={data && data["input_filename"]} role="presentation" onClick={() => downloadFile(data, "original")}>
												{data && data["input_filename"]}
											</span>
										) : (
											<span title={data && data["input_filename"]} role="presentation">
												{data && data["input_filename"]}
											</span>
										)}
									</Filename>
								}
							/>
							<InfoDetail data="Received on:" detail={data && data["createdDate"]} />
							<InfoDetail data="Resolved on:" detail={data && data["resolvedDate"] ? data["resolvedDate"] : "N/A"} />
							<InfoDetail data="Resolved by:" detail={data && data["users__email"] ? data["users__email"] : "N/A"} />
							<InfoDetail data="Success Record Insertion:" detail={data["success_record_insert"] && data["success_record_insert"] == 1 ? "ON" : "OFF"} />
						</DetailCard>
					) : (
						<Skeleton
							sx={{
								height: "11rem",
								width: "100%",
								transform: "none",
								bgcolor: "${AppThemes.light.colors.neutral200}"
							}}
							animation="wave"
						/>
					)}
				</Grid>
				{/* <Grid item xs={2}>
					<Button label="Mark Resolved" disabled={true} variant="contained" size="small" class="rightFloat" />
				</Grid> */}
			</Grid>
			<Grid sx={{ marginBottom: 2 }} container justifyContent="flex-end">
				<Button
					label={
						<Box>
							<span>Error File </span> <Download class="vertical-middle" size={16} />
						</Box>
					}
					onClick={() => downloadFile(data, "error")}
					size="small"
					variant="contained"
					disabled={data && data["error_filename"] === null}
				/>
			</Grid>
			<TransactionDetailContainer>
				<Table showFilters={false} isSelectable={false} columns={columns} data={transactionRecord} total={total || tableData.length} page={tableEvent.page || 1} pageSize={tableEvent.pageSize || 5} handleEvent={(event) => handleTableEvent(event)} isLoading={isLoading} />
			</TransactionDetailContainer>

			<ToastContainer />
		</div>
	);
};

export default TransactionDetail;
