const dataConfigs = {
	CRUDAPI: { value: "/v2/autocrud/" },
	integrationAPI: { value: "/v2/integration/" },
	fileDownloadAPI: { value: "/attachments/download?" },
	ConnectionStatus: [
		{ key: 0, value: "InActive" },
		{ key: 1, value: "Active" }
	],
	ModeValues: [
		{ key: 1, value: "Inbound", logo: "\u2193" },
		{ key: 2, value: "Outbound", logo: "\u2191" },
		{ key: 3, value: "Int", logo: "\u2191" }
	],
	TransactionStatus: [
		{ key: 0, value: "Failed" },
		{ key: 1, value: "Success" },
		{ key: 2, value: "Partial Success" }
		// { key: 3, value: "Resolved" }
	],
	IMAGES_TO_DOWNLOAD: [
		{ key: "images/s3.svg", shouldWait: true },
		{ key: "images/sqs.svg", shouldWait: true },
		{ key: "images/email.svg", shouldWait: true },
		{ key: "images/list.svg", shouldWait: false },
		{ key: "images/audit.svg", shouldWait: false },
		{ key: "images/configure.svg", shouldWait: false }
	],
	auditIcon: "",
	listIcon: "",
	configureIcon: ""
};
export default dataConfigs;
