import { Home, Data_1, TaskSettings } from "@carbon/icons-react";

import { RouteType } from "./config";
import IntegrationHubDashboard from "../components/dashboard/integrationHubDashboard";
import IntegrationConfigurationsList from "../components/integrationConfigurationsList";
import ConnectionsList from "../components/connections/connectionsList";
import TransactionDetail from "../components/dashboard/transactionDetail";

const appRoutes: RouteType[] = [
	{
		path: "/integrationhub/dashboard",
		element: <IntegrationHubDashboard />,
		title: "Dashboard",
		icon: <Home size={20} />,
		visible: true
	},
	{
		path: "/integrationhub/dashboard/:id",
		element: <IntegrationHubDashboard />,
		title: "Dashboard",
		icon: <></>,
		visible: false
	},
	{
		path: "/integrationhub/configuration",
		element: <IntegrationConfigurationsList />,
		title: "Configurations",
		icon: <TaskSettings size={20} />,
		visible: true
	},
	{
		path: "/integrationhub/connections",
		element: <ConnectionsList />,
		title: "Connections",
		icon: <Data_1 size={20} />,
		visible: true
	},
	{
		path: "/integrationhub/transactiondetail/:id",
		element: <TransactionDetail />,
		title: "Transaction Detail",
		icon: <></>,
		visible: false
	}
];

export default appRoutes;
