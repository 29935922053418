import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "@fontsource/source-sans-pro";

import MainLayout from "./pages/MainLayout";
import { routes } from "./core/index";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API, setAuthUser } from "@roambee/client-utility";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Loader } from "@roambee/client-styleguide";

import "./App.scss";
import { createContext, useEffect, useState } from "react";
import AWS from "aws-sdk";
import dataConfigs from "./configs/dataConfigs";
import { downloadS3Images } from "./core/s3Downloader.js";
import useMasterData from "./hooks/useMasterData";
import queryParamsConfig from "./configs/queryParamsConfig";
const MasterDataContext = createContext(null);

function App() {
	const [hasSession, setHasSession] = useState<boolean | null>(null);
	const [loading, setIsLoading] = useState<boolean | null>(true);
	// const navigate = useNavigate();
	const [integrationType, setIntegrationType] = useState([]);
	const [connector, setConnector] = useState([]);

	// const handleDownload = () => {
	// 	dataConfigs.IMAGES_TO_DOWNLOAD.forEach(({ key, shouldWait }) => {
	// 		const params = {
	// 			Bucket: process.env.INTEGRATIONHUB_S3_BUCKET,
	// 			Key: key
	// 		};
	// 		downloadS3Images(params, shouldWait);
	// 	});
	// };

	const integrationTypeData = useMasterData(queryParamsConfig.getIntegrationTypeList);
	const connectorData = useMasterData(queryParamsConfig.getConnectors);

	useEffect(() => {
		API("POST", "/user/me", {})
			.then((result) => {
				setHasSession(true);
				setAuthUser(result);
			})
			.catch(() => {
				setHasSession(false);
			})
			.finally(() => {
				setIsLoading(false); // Regardless of success or error, loading is done
			});
	}, []);

	useEffect(() => {
		if (integrationTypeData[0] == null) {
			return;
		}

		if (integrationTypeData[0] != null) {
			integrationTypeData[0].forEach((key) => {
				const obj = { key: key.id, value: key.type };
				integrationType.push(obj);
			});
		}

		if (connectorData[0] == null) {
			return;
		}

		if (connectorData[0] != null) {
			connectorData[0].forEach((key) => {
				const obj = {
					key: key.type,
					value: key.name,
					id: key.id,
					config: key.config,
					type: key.type,
					created_date: key.created_date,
					logo_path: key.logo_path,
					description: key.description,
					active: key.active,
					created_by: key.created_by,
					updated_date: key.updated_date,
					updated_by: key.updated_by,
					name: key.name
				};
				connector.push(obj);
			});
		}
		// handleDownload();
	}, [integrationTypeData[0], connectorData[0]]);

	return (
		<BrowserRouter>
			<MasterDataContext.Provider value={{ integrationType: integrationType, connector: connector }}>
				{hasSession ? (
					<Routes>
						<Route path="/integrationhub" element={<MainLayout />}>
							{routes}
						</Route>
					</Routes>
				) : (
					!loading && <Navigate to="/auth" />
				)}
				{loading && <Loader />}
			</MasterDataContext.Provider>
		</BrowserRouter>
	);
}

export default App;
export { MasterDataContext };
